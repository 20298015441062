export interface Theme {
  name: string;
  properties: any;
}

export const light: Theme = {
  name: 'light',
  properties: {
    '--backDroop': 'rgba(0, 0, 0, 0.8)',
    '--color-1': 'black',
    '--color-2': 'white',
    '--color': 'white',
    '--textura': 'url("/assets/FONDO-TEXTURA-BLANCA.jpg")',
    '--texturaI': 'url("/assets/FONDO-TEXTURA-NEGRO.jpg")',
    '--texturaLarge' : 'url("/assets/FONDO-TEXTURA-BLANCA-LARGE.jpg")',
    '--texturaLargeI': 'url("/assets/FONDO-TEXTURA-NEGRO-LARGE-I.jpg")',
    '--colorBgTxt': '#E3DBD9',
    '--colorBgArt': 'white',
    '--bgHomePrincipal' : 'url("assets/Banner-Home/Light/Principal-Light.jpg")',
    '--bgHomeHE' : 'url("assets/Banner-Home/Light/He-Light.jpg")',
    '--bgHomeLcc' : 'url("assets/Banner-Home/Light/Lcc-Light.jpg")',
    '--bgHomeMlpx' : 'url("assets/Banner-Home/Light/Multiplex-Light.jpg")',
    '--bgHomePrincipalRes' : 'url("assets/Banner-Home/Light/Responsive/Principal-Light.jpg")',
    '--bgHomeHERes' : 'url("assets/Banner-Home/Light/Responsive/He-Light.jpg")',
    '--bgHomeLccRes' : 'url("assets/Banner-Home/Light/Responsive/Lcc-Light.jpg")',
    '--bgHomeMlpxRes' : 'url("assets/Banner-Home/Light/Responsive/Multiplex-Light.jpg")',
    '--txtBtnNL' : '\'Light\'',
    '--positionTxtBtnNL' : '62%',
    '--speed' : '500ms',
    '--varOpacity' : '20',
    '--vart': 'fadeIn ease 2s',
  }
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--backDroop': 'rgba(0, 0, 0, 0.8)',
    '--color-1': 'white',
    '--color-2': 'red',
    '--color': 'black',
    '--textura' : 'url("/assets/FONDO-TEXTURA-NEGRO.jpg")',
    '--texturaI' : 'url("/assets/FONDO-TEXTURA-BLANCA.jpg")',
    '--texturaLarge': 'url("/assets/FONDO-TEXTURA-NEGRO-LARGE.jpg")',
    '--texturaLargeI': 'url("/assets/FONDO-TEXTURA-BLANCA-LARGE-I.jpg")',
    '--colorBgTxt': 'black',
    '--colorBgArt': 'black',
    '--bgHomePrincipal' : 'url("assets/Banner-Home/Dark/Principal-Dark.jpg")',
    '--bgHomeHE' : 'url("assets/Banner-Home/Dark/He-Dark.jpg")',
    '--bgHomeLcc' : 'url("assets/Banner-Home/Dark/Lcc-Dark.jpg")',
    '--bgHomeMlpx' : 'url("assets/Banner-Home/Dark/Multiplex-Dark.jpg")',
    '--bgHomePrincipalRes' : 'url("assets/Banner-Home/Dark/Responsive/Principal-Dark.jpg")',
    '--bgHomeHERes' : 'url("assets/Banner-Home/Dark/Responsive/He-Dark.jpg")',
    '--bgHomeLccRes' : 'url("assets/Banner-Home/Dark/Responsive/Lcc-Dark.jpg")',
    '--bgHomeMlpxRes' : 'url("assets/Banner-Home/Dark/Responsive/Multiplex-Dark.jpg")',
    '--txtBtnNL' : '\'Dark\'',
    '--positionTxtBtnNL' : '45%',
    '--speed' : '500ms',
    '--varOpacity' : '30',
    '--vart': 'fadeIn ease 2s',
  }
};
