import { NavService } from "src/app/services/nav.service";
import { Component, OnInit } from "@angular/core";
import { ThemeService } from "src/app/services/theme.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  urlActual = " ";
  header = true;

  constructor(
    private themeService: ThemeService,
    private router: Router,
    private navService: NavService
  ) {}

  ngOnInit() {
    this.onTheme();
  }

  onTheme() {
    const fecha = new Date();
    const hora = fecha.getHours();
    if (hora < 6 || hora > 17) {
      const e2 = document.getElementById("btnTheme");
      (e2 as HTMLInputElement).checked = true; // "hint" the type to TypeScript
      this.themeService.setDarkTheme();
    } else {
      this.themeService.setLightTheme();
    }
  }

  toggleTheme() {
    if (this.themeService.isDarkTheme()) {
      this.themeService.setLightTheme();
    } else {
      this.themeService.setDarkTheme();
    }
  }

  changeFragment(path: string, fragment: string) {
    this.openMenu();
    this.navService.changePos(path, fragment);
  }

  openMenu() {
    const url = window.location.hash.split("/")[1];
    this.urlActual = url;

    const overlayNavigation = document.querySelector(".overlay-navigation");
    const divContentMenu1 = document.querySelector(".divContentMenu1");
    overlayNavigation.classList.toggle("overlay-active");
    divContentMenu1.classList.toggle("menu-active");

    if (
      overlayNavigation.classList.contains("overlay-active") &&
      divContentMenu1.classList.contains("menu-active")
    ) {
      overlayNavigation.classList.remove("overlay-slide-up");
      overlayNavigation.classList.add("overlay-slide-down");
      divContentMenu1.classList.remove("divContentMenu1-close");
      divContentMenu1.classList.add("divContentMenu1-open");
      document.querySelector("body").style.overflowY = "hidden";
    } else {
      overlayNavigation.classList.remove("overlay-slide-down");
      overlayNavigation.classList.add("overlay-slide-up");
      divContentMenu1.classList.remove("divContentMenu1-open");
      divContentMenu1.classList.add("divContentMenu1-close");
      document.querySelector("body").style.overflowY = "scroll";
    }
  }
}
