<app-header></app-header>
<div class="btn-whatsapp">
    <a href="https://api.whatsapp.com/send?phone=+573012977189&text=Hola deseas saber mas acerca de nosotros y nuestros servicios?."
        class="icono" target="_blank">
        <i id="" class="fa fa-whatsapp"></i>
    </a>
</div>
<div class="btn-arrow" (click)="isAtBottom ? scrollToTop() : null">
    <i id="" [class]="isAtBottom ? 'iconArrow fa fa-arrow-up' : 'iconArrow fa fa-arrow-down'"></i>
</div>

<router-outlet></router-outlet>

<app-footer></app-footer>