import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ModalComponent } from "../shared/modal/modal.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  openDialog(typeM: string, contentM: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90%";
    dialogConfig.maxWidth = "90";
    dialogConfig.backdropClass = "myBackModalClass";
    dialogConfig.panelClass = "modalClassCustom";
    dialogConfig.data = {
      content: contentM,
      type: typeM,
    };

    this.dialog.open(ModalComponent, dialogConfig);
  }
}
