import { element } from "protractor";
import { ActivatedRoute } from "@angular/router";
import { Component, HostListener, OnInit } from "@angular/core";

import { ThemeService } from "src/app/services/theme.service";
import { NgwWowService } from "ngx-wow";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  // tslint:disable-next-line: ban-types
  public apiURL = window.location.hash.split("/")[1];

  // tslint:disable-next-line: ban-types
  public bannerHe: String = "bannerHE";

  constructor(private wowService: NgwWowService) {
    this.wowService.init({
      boxClass: "wow", // default
      animateClass: "animate__animated", // default
      offset: 0, // default
      mobile: true, // default
      live: true, // default
    });
  }
  ngOnInit(): void {}
  isAtBottom: boolean = false;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    // Check if near bottom of the page
    const pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      window.innerHeight;
    const max = document.documentElement.scrollHeight;

    // Setting the flag to true if at the bottom
    this.isAtBottom = pos >= max;
  }

  scrollToTop() {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  }
}
