import { Component, OnInit } from "@angular/core";
import { ModalService } from "./../../services/modal.service";
@Component({
  selector: "app-team-section",
  templateUrl: "./team-section.component.html",
  styleUrls: ["./team-section.component.css"],
})
export class TeamSectionComponent implements OnInit {
  dataTeam = [];

  constructor(private modal: ModalService) {}

  ngOnInit(): void {
    this.startDataTeam();
  }

  openModal(type: string, content: string) {
    this.modal.openDialog(type, content);
  }

  startDataTeam() {
    this.dataTeam = [
      {
        img: "/HANS FERRIN.png",
        name: "Hans Ferrin",
        jobTitle: "CEO y Fundador LCC- HE SAS",
        profile:
          "Ingeniero Financiero Especialista en Gestión de Portafolios de Inversión y Valoración de Empresas, experto en diagnóstico y gestión financiera estructural y productor de educación digital.",
      },
      {
        img: "/DANIELA BURAGLIA.png",
        name: "Daniela Buraglia",
        jobTitle: "Gerente de Diseño y Fundadora LCC-HE SAS",
        profile:
          "Diseñadora de Moda, Diseñadora Gráfica experta en contenidos digitales y posicionamiento de marcas en ambientes digitales, experta en contenidos digitales y diseño de marcas.",
      },
      {
        img: "/ANGIE ESPITIA.png",
        name: "Angie Espitia",
        jobTitle: "Jefe Comercial",
        profile:
          "Comunicadora Social, con experiencia en dirección comercial de negocios, visualización de marcas y redes sociales, experta en negocios B2B y gestión de cierres de negocios de gran cuantía y copywriter.",
      },
      {
        img: "/CLAUDIA DURAN.png",
        name: "Claudia Durán",
        jobTitle: "Consultora asociada",
        profile:
          "Profesional en Finanzas experta en productos financieros, inversiones diversificadas, agente con experiencia en negocios e inversiones en bienes raíces, alta experiencia en consultoría financiera con enfoque en diseño e implementación de flujos de caja.",
      },
      {
        img: "/ANDREA DIAZ.png",
        name: "Andrea Díaz",
        jobTitle: "Agente comercial",
        profile:
          "Instrumentadora Quirúrgica, con alta experiencia en comercio de productos del sector salud, asesora de estrategia de ventas, cierres de negocios de altas cuantías, experta en comercialización de productos intangibles, altos conocimientos en gerencia y administración.",
      },
      {
        img: "/JUAN JOSE FERRIN.png",
        name: "Juan José Ferrin",
        jobTitle: "Diseñador Junior",
        profile:
          "Revelación 2023, conocimientos operativos en redes sociales, publicaciones digitales y copywriter, ha incursionado en el mundo de la escritura de novelas que combina investigación y ciencia ficción.",
      },
    ];
  }
}
