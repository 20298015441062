import { Component, OnInit } from "@angular/core";
import { ModalService } from "./../../services/modal.service";
import { ContentService } from "./../../services/content.service";

@Component({
  selector: "app-he-section",
  templateUrl: "./he-section.component.html",
  styleUrls: ["./he-section.component.css"],
})
export class HeSectionComponent implements OnInit {
  constructor(
    private modal: ModalService,
    private contentHtml: ContentService
  ) {}

  hrRight = this.contentHtml.hrRight;
  hrLeft = this.contentHtml.hrLeft;

  businesServices = [];
  personalFinance = [];
  conferenceEvents = [];

  ngOnInit() {
    this.startDataItemServ();
  }

  openModal(type: string, content: string) {
    this.modal.openDialog(type, content);
  }

  downloadPDF(brochure: string) {
    window.open(brochure);
  }

  startDataItemServ() {
    this.businesServices = [
      {
        typeModal: "servicioHe",
        contentModal: "service2",
        title: "CONSULTORÍA FINANCIERA Y DE NEGOCIOS",
        subTitle: "Tratamiento financiero (ADT)",
        brochure: "assets/PDF/BROCHURE-FINANCIERO-LCC.pdf",
      },
      {
        typeModal: "servicioHe",
        contentModal: "service3",
        title: "EMPRENDIMIENTO Y NUEVOS NEGOCIOS",
        brochure: "assets/PDF/BROCHURE-FINANCIERO-LCC.pdf",
      },
      {
        typeModal: "servicioHe",
        contentModal: "service4",
        title: "CONVENIOS CON ASESORES LEGALES PARA TU EMPRESA",
        brochure: "assets/PDF/BROCHURE-FINANCIERO-LCC.pdf",
      },
    ];
  }
}
