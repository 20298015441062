<div class="w-100 contService">
    <mat-dialog-actions>
        <button class="btn-close fa fa-times" mat-button mat-dialog-close></button>

    </mat-dialog-actions>
    <mat-dialog-content class="dialog-content">
        <app-carousel *ngIf="type === 'carousel'" [typeContent]="content"></app-carousel>
        <img class="imgModal" *ngIf="type === 'servicioLcc'" src="assets/Lcc/serviceModal/{{content}}.jpg" alt="">
        <img class="imgModal" *ngIf="type === 'servicioHe'" src="assets/He/ServiceModal/{{content}}.jpg" alt="">
        <img class="imgModal" *ngIf="type === 'metodologias'" src="assets/Banner-Home/Light/Multiplex-Light.jpg" alt="">
    </mat-dialog-content>
</div>