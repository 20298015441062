import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor() { }

  hrRight = `<div class="row divHr">
              <hr class="order-3 order-lg-0">
              <hr class="order-2 order-lg-1">
              <hr class="order-1 order-lg-2">
              <hr class="order-0 order-lg-3">
            </div>`;
  hrLeft = `<div class="row divHr">
              <hr class="order-lg-3">
              <hr class="order-lg-2">
              <hr class="order-lg-1">
              <hr class="order-lg-0">
            </div>`;

}
