<section id="lanzamientos ">
    <!-- //* Inicio Del Banner Principal  *// -->

    <div class="wow  animate__backInUp w-100  heDiv ">
        <div class="bannerLanzamientos w-100 h-100">
            <div class="w-100 h-100 bg-text-lan alc al-r">
                <div id="" class="">
                    <p data-wow-delay="0.7s" class=" wow animate__fadeInTopLeft subText ">CURSO</p>
                    <p data-wow-delay="0.7s" class=" wow animate__fadeInTopLeft subText ">EMPRESARIAL</p>
                    <p data-wow-delay="0.9s" class=" wow animate__fadeInTopRight princText  ">EXCEL</p>
                    <p data-wow-delay="1s" class=" wow animate__fadeInBottomLeft princText  ">PARA LA</p>
                    <p data-wow-delay="1.2s" class=" wow animate__fadeInBottomRight princText ">GESTIÓN DE</p>
                    <p data-wow-delay="1.2s" class=" wow animate__fadeInBottomRight princText ">DATOS</p>
                </div>
            </div>

        </div>
    </div>
    <!-- //* Fin Del Banner Principal  *// -->
    <div class="divLanzamientos">
        <div class=" py-4 divRegisterInfo w-100">
            <div class="w-100 row">
                <div class="formInfoF col-12 col-md-6 p-0 ">
                    <div class="wow animate__lightSpeedInLeft  formAsis bgTextura ">
                        <h4 data-wow-delay="0.2s" class="wow animate__fadeInDown textFontAnthon titleFormL mb-4">Más
                            Información</h4>
                        <form class="form-horizontal">
                            <!-- Text input-->
                            <div class="form-group">
                                <div data-wow-delay="0.4s" class="wow animate__fadeInDown col-12 p-0">
                                    <input id="nombreL" name="nombreL" type="text" placeholder="Nombre"
                                        class="inputLanzamientos" required="">

                                </div>
                            </div>
                            <!-- Text input-->
                            <div class="form-group">
                                <div data-wow-delay="0.4s" class="wow animate__fadeInDown col-md-12 p-0 mb-4">
                                    <input id="correoL" name="correoL" type="text" placeholder="Correo"
                                        class="inputLanzamientos" required="">
                                </div>
                            </div>
                            <!-- Text input-->
                            <div class="form-group">
                                <div data-wow-delay="0.4s" class="wow animate__fadeInDown col-md-12 p-0 mb-4">
                                    <input id="celularL" name="celularL" type="text" placeholder="Celular"
                                        class="inputLanzamientos" required="">
                                </div>
                            </div>
                            <!-- button submit-->
                            <div class="form-group mt-5">
                                <div class="col-md-12 p-0 row">
                                    <div data-wow-delay="0.6s" class="wow animate__fadeInDown w-100 mb-1">
                                        <button type="button"
                                            class="textFontDidact btnPublic float-right mr-3">Enviar</button>
                                    </div>
                                    <div data-wow-delay="0.6s" class="wow animate__fadeInDown w-100">
                                        <button type="button" class="textFontDidact btnPublic float-right mr-3">Solicite
                                            más información</button>
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="w-100 h-100  contService">
                        <div class="w-100  contService1 ">
                            <div class="categoriaArt wow animate__zoomInRight">
                                <div class="row contService1 titleLanzamiento m-0 mb-3">
                                    <h6>Categoria</h6>
                                    <hr>
                                    <h4>Tegnologia</h4>
                                </div>
                                <div class="txtTitleLaz mb-3">
                                    <h4 class=" textFontAnthon">Excel para la Gestión de Datos, Curso Corporativo.</h4>
                                </div>
                                <div class="infoLanzamiento">
                                    <h4 class="txtInfo textFontDidact">Contáctanos y un agente comercial atenderá tus
                                        preguntas sobre este programa.
                                    </h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wow animate__fadeIn lineHrLan">
            <hr>
        </div>
        <!-- Video Principal-->
        <div id="videoPrincipal" class="video w-100 contService">
            <iframe class="wow animate__lightSpeedInRight" src="https://player.vimeo.com/video/76324941" frameborder="0"
                allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>


        <div class="DivTxtFilosofias p-1 p-md-5">
            <div class="wow animate__lightSpeedInLeft txtFilosofia textFontDidact text-justify ">
                <h1>CURSO EXCEL PARA LA GESTIÓN DE DATOS</h1>

                <h2>¿Por qué estudiar Excel?</h2>
                <p>En la actualidad vemos como la administración de la información es cada día más relevante en la
                    presentación de
                    informes corporativos, modelamiento de datos, tableros de control de datos, proceso información,
                    entre otros; todos
                    estos para mejorar la toma de decisiones y la administración de la información empresarial.</p>
                <p>Excel es una herramienta de hoja de cálculo que permite tratar adecuadamente la información y
                    procesarla matemática y
                    sistemáticamente con el objetivo de aprovechar la información para la organización.</p>

                <h2>¿Excel ya no es vigente?</h2>
                <p>Si bien es cierto que hoy en día se cuenta con el acceso a herramientas sistemáticas y software que
                    generan reportes
                    y administran la información, cada compañía cuenta con su propia manera de administrar y comprender
                    los datos. Excel
                    de Microsoft es tan importante que en la actualidad la mayoría de estas herramientas exportan su
                    información en
                    formato de Excel para brindar a los usuarios la posibilidad de administrar su información de manera
                    personalizada.
                </p>

                <h2>¿Por qué es importante la gestión de datos en Excel?</h2>
                <p>Una compañía que comprende su información de manera detallada es una compañía que tendrá ventaja
                    frente a su
                    competencia y logrará sacar provecho a las condiciones de mercado, basado sus decisiones en los
                    resultados del
                    procesamiento de sus datos. Excel con un enfoque para gestionar y administrar datos que provienen de
                    software
                    especializados, o de áreas de construcción de información; como lo es el área contable, tributaria o
                    financiera, se
                    convierte en un programa académico especial para compañías que se enfocan en tomar decisiones por
                    medio de la
                    ciencia de datos.</p>

                <h2>¿Quién puede tomar este curso?</h2>
                <p>Todo aquel que trabaje con Excel de Microsoft y desee profundizar en funciones que le permitan hacer
                    minería de
                    datos, extraer información de grandes bases de datos, organizar archivos planos, crear reportes por
                    medio de tablas
                    dinámicas y finalizar sus procesos creando tableros de control llamados Dashboard. El único
                    requisito es tener
                    conocimientos básicos en la hoja de cálculo.</p>

                <h2>Objetivo del programa</h2>
                <p>Brindar a los asistentes las herramientas básicas, intermedias y avanzadas de la hoja de Cálculo de
                    Excel de
                    Microsoft, con el objetivo principal de adquirir competencias y destrezas en el manejo de funciones
                    para procesar
                    información y extraer información de bases de datos con alto número de registros, a su vez
                    comprender la importancia
                    de la presentación de información en la construcción de tableros de control Dashboard.</p>

                <h2>Metodología</h2>
                <p>Nuestra compañía de entrenamientos corporativos cuenta con dos metodologías con el objetivo que cada
                    empresa pueda
                    elegir la que más se adapte a sus necesidades de tiempo y flujo de trabajo:</p>
                <ul>
                    <li><strong>a. Sincrónica 100%:</strong> Son sesiones en vivo por medio de plataforma como Zoom,
                        Meets, Team, Webex,
                        entre otras, las sesiones cuentan con un instructor en vivo que va desarrollando las lecciones y
                        dando las
                        explicaciones pertinentes. Los estudiantes cuentan con una carpeta virtual de descarga de cada
                        lección para ir
                        desarrollando a la par del instructor con el fin de aclarar dudas dentro de la misma sesión.
                    </li>
                    <li><strong>b. Hibrida:</strong> Son sesiones en video, pero se cuenta con una reunión sincrónica
                        (en vivo) una vez
                        a la semana con el objetivo de aclarar dudas y nivelar al grupo para garantizar una apropiación
                        adecuada del
                        conocimiento.</li>
                </ul>

                <h2>Certificación</h2>
                <p>Nuestra compañía por medio de nuestro servicio de educación EDUPLEX BS, certificará a los asistentes
                    como un curso
                    libre de capacitación en Excel para la Gestión de Datos. El certificado emitido será de manera
                    digital una vez el
                    asistente presente dos soluciones o retos que se le presentarán, los asistentes tendrán dos fechas
                    estipuladas para
                    la entrega, adicionalmente cada asistente deberá entregar vía correo la totalidad de las lecciones
                    desarrolladas en
                    clase.</p>

                <h2>Duración del Programa</h2>
                <p>El programa tiene una duración de 24 horas de clase para la modalidad sincrónica que se desarrollarán
                    según
                    cronograma en 4 semanas con mínimo 2 sesiones por semana cada una de 3 horas o lo equivalente a 6
                    horas semanales,
                    adicionalmente para el desarrollo de actividades de entrega se tendrá un plazo de 2 semanas
                    adicionales, con esta
                    fecha cumplida se finaliza el curso.</p>
                <p>Para modalidad Hibrida el curso liberará las lecciones en video de manera semanal durante 4 semanas,
                    en esta
                    modalidad se agenda 4 sesiones, una por semana, adicionalmente para el desarrollo de actividades de
                    entrega se
                    tendrá un plazo de 2 semanas adicionales, con esta fecha cumplida se finaliza el curso.</p>

                <h2>Programa de Capacitación</h2>
                <h3>Módulo 1:</h3>
                <ul>
                    <li>Accesos rápidos de Excel</li>
                    <li>Gestión de datos de archivos planos</li>
                    <li>Sintaxis de funciones lógicas</li>
                    <li>Anidaciones de funciones lógicas</li>
                    <li>Sintaxis de funciones de búsqueda</li>
                    <li>Anidaciones de funciones de búsqueda</li>
                    <li>Formulación con nombres</li>
                </ul>

                <h3>Módulo 2:</h3>
                <ul>
                    <li>Funciones de extracción de datos</li>
                    <li>Funciones de texto</li>
                    <li>Actualización de funciones Office 365</li>
                    <li>Funciones avanzadas de búsqueda y extracción de datos</li>
                </ul>

                <h3>Módulo 3:</h3>
                <ul>
                    <li>Filtros sencillos y avanzados</li>
                    <li>Alerta de datos con validación de colores</li>
                    <li>Tablas Dinámicas básicas</li>
                    <li>Tablas dinámicas con campo calculado</li>
                    <li>Tablas dinámicas relacionadas</li>
                    <li>Gráficos dinámicos</li>
                </ul>

                <h3>Módulo 4:</h3>
                <ul>
                    <li>Introducción a Power Pivot</li>
                    <li>Modelo de datos en Power Pivot</li>
                    <li>Introducción al lenguaje DAX</li>
                    <li>Segmentadores y escalas de tiempo</li>
                    <li>Diseño de tableros de control Dashboard</li>
                </ul>

                <h2>Precio y modalidad del programa</h2>
                <p>Excel para la Gestión de Datos es un programa corporativo especial para empresas que desean
                    incrementar su
                    conocimiento en la hoja de cálculo Excel de Microsoft y mejorar su administración de datos.</p>
                <p><strong>Valor del programa:</strong> $6´900.000 + IVA. Seis millones novecientos mil pesos por grupo
                    empresarial.</p>
                <p><strong>Número máximo de estudiantes:</strong> 20 Estudiantes</p>

            </div>

        </div>


    </div>
</section>