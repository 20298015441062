import { ModalService } from '../../services/modal.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { NavService } from '../../services/nav.service';
import { EmailContactService } from '../../services/email-contact.service';
import { FormContact } from '../../class/FormContact';

import swal from 'sweetalert2';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  // tslint:disable-next-line: ban-types
  urlPath = '';

  dataPost = [];

  ngOnInit() {
    this.cargaDataPost();
  }

  // tslint:disable-next-line:member-ordering
  FormContact: FormContact = new FormContact();

  constructor(private http: HttpClient, private emailService: EmailContactService,
              private modal: ModalService, private navService: NavService) {
    this.urlPath = window.location.pathname.split('/')[1];
  }

  openModal() {
    this.modal.openDialog('img1', 'img');
  }

  private enviarEmail(form: NgForm) {
    this.emailService.enviarEmail(this.FormContact).subscribe(
      response => {
        // tslint:disable-next-line: max-line-length
        swal.fire('Solicitud Contacto', `Tu solicitud fue enviada  ${(response.name).split(' ')[0]}, en breve nos estaremos comunicando contigo`, 'success' );
        form.resetForm();
      });
  }
  onSubmit(form: NgForm) {
    this.enviarEmail(form);
  }



  changeFragment(path: string, fragment: string) {
    this.navService.changePos(path, fragment);
  }

  cargaDataPost() {
    this.dataPost = [

      {
        artTitle: 'Emprendimiento no emprendimiento.',
        postedBy: 'Hans Emil',
        datePost: '15-03-2020',
        fragment: ''
      },
      {
        artTitle: 'Ventas Con redes sociales, ¿Funcionan o no?.',
        postedBy: 'Hans Emil',
        datePost: '15-03-2020',
        fragment: ''
      },
      {
        artTitle: 'Sin información no hay gestión.',
        postedBy: 'Hans Emil',
        datePost: '15-03-2020',
        fragment: ''
      }
    ];
  }

}
