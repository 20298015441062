<section class="sectionPrincipal noSelect">


    <!-- Banner Principal -->
    <div id="" class=" contServiceHome wow  animate__backInUp  p-0 heDiv text-white">
        <div class="home alc al-c">
            <div id="" class="">
                <p data-wow-delay="0.7s" class=" wow animate__fadeInTopLeft subText ">BIENVENIDO AL</p>
                <p data-wow-delay="0.9s" class=" wow animate__fadeInTopRight princText  ">FUTURO</p>
                <p data-wow-delay="1s" class=" wow animate__fadeInBottomLeft subText  ">BIENVENIDO AL MUNDO</p>
                <p data-wow-delay="1.2s" class=" wow animate__fadeInBottomRight princText ">LIFE CONTROL CHANNEL</p>
            </div>
        </div>

    </div>

    <div class="col-12 colorBg contService txtAfterBanner ">
        <div data-wow-delay="0.5s" class="wow animate__flipInX txtColor1 contService">
            <h1 class="m-0 lhe textUpLine textFontAnthon ">LCC S.A.S METODOLOGIAS DE CONOCIMIENTOS</h1>
            <hr class=" line lhe">
            <h5 class="m-0 lhe textDownLine textFontDidact ">LCC S.A.S somos una empresa fundada en el año 2018 con el
                objetivo de apoyar los procesos de visualización de marca, administración de redes sociales, contenido
                para redes sociales, consultoría en desarrollo de herramientas informáticas y producción de contenido
                educativo con énfasis en negocios.
                En los últimos años LCC S.A.S ha desarrollado diferentes metodologías de apropiación del conocimiento
                empresarial en áreas como...</h5>


            <div class="wow animate__bounceIn animate__slow seeMore my-2 ">
                <button (click)="openModal('metodologias', '')">Leer más</button>
            </div>




        </div>
        <!-- boton Leer mas del Banner pricipal-->
        <div class="buttonVerMas mx-2" *ngFor="let item of businesServices">
            <div class="wow animate__bounceIn animate__slow seeMore w-100">
                <button (click)="openModal(item.typeModal, item.contentModal)">Leer más</button>
            </div>
        </div>
    </div>
    <!-- Banner HE Consultores  -->
    <div data-wow-delay="0.3s" id="bannerHE"
        class=" wow animate__fadeIn animate__slow backImg  heConsultoresBanner bannerFather col-sm-12 col-lg-12 contService">
        <div class="contService">
            <div class="row contService fatherTxt">
                <!-- <div data-wow-delay="0.5s" class="wow animate__fadeInTopLeft  txtBanner mrTxtBanner txtHe "><span class="letterLast"></span></div> -->
                <div data-wow-delay="0.5s" class="wow animate__fadeInBottomRight txtBanner txtConsult">CONSULTORE<span
                        class="letterLast">S</span></div>
            </div>

            <div data-wow-delay="0.5s" class=" wow animate__bounceIn animate__slow  textWhite row contService">
                <div class="row lineSeeDee front">
                    <hr class="lineSee f4 ml-md-2 ml-1">
                    <hr class="lineSee f3 ml-md-2 ml-1">
                    <hr class="lineSee f2 ml-md-2 ml-1">
                    <hr class="lineSee f1 ml-md-2 ml-1">
                </div>
                <div class="buttonVerMas mx-2">
                    <a class="textFontDidact wow" routerLink="/He-Consultores" title="He Consultores ">Leer más</a>
                </div>
                <div class="row lineSeeDee res">
                    <hr class="lineSee f1 mr-md-2 mr-1">
                    <hr class="lineSee f2 mr-md-2 mr-1">
                    <hr class="lineSee f3 mr-md-2 mr-1">
                    <hr class="lineSee f4 mr-md-2 mr-1">
                </div>
            </div>
        </div>
    </div>
    <!-- Texto He -->
    <div class="col-12 colorBg contService txtAfterBanner">
        <div data-wow-delay="0.5s" class="wow animate__flipInX txtColor1 contService">
            <h1 class="m-0 lhe textUpLine textFontAnthon ">NO TRABAJES SIN RUMBO</h1>
            <hr class="line  lhe">
            <h5 class="m-0 lhe textDownLine textFontDidact ">La consultoría que realizamos está enfocada en mejorar las
                prácticas empresariales, por esta razón nuestra consultoría es un asesoramiento y acompañamiento basados
                en la educación empresarial, lo cual hemos denominado “Consultoría Instructiva”, nuestro objetivo es
                enseñar e implementar las herramientas a la empresa con una manera disruptiva,
                eficiente y directa en programas especiales para su empresa.</h5>
        </div>
    </div>
    <!-- Banner LCC -->
    <div data-wow-delay="0.3s " id="bannerLcc "
        class=" backImg wow animate__fadeIn animate__slow lccBanner bannerFather  col-12 backImgcontService">
        <div class="contService">
            <div class="row contService fatherTxt">

                <div data-wow-delay="0.5s" class="wow animate__fadeInTopLeft txtBanner txtLife mrTxtBanner">LCC</div>
                <div data-wow-delay="1s" class="wow animate__fadeIn txtBanner txtControl mrTxtBanner">PRODUCCION DE
                </div>
                <div data-wow-delay="0.5s" class="wow animate__fadeInBottomRight txtBanner txtCONTENIDOS">CONTENIDOS
                </div>

            </div>

            <div data-wow-delay="0.5s" class=" wow animate__bounceIn animate__slow  textWhite row contService">
                <div class="row lineSeeDee front">
                    <hr class="lineSee f4 ml-md-2 ml-1">
                    <hr class="lineSee f3 ml-md-2 ml-1">
                    <hr class="lineSee f2 ml-md-2 ml-1">
                    <hr class="lineSee f1 ml-md-2 ml-1">
                </div>
                <div class="buttonVerMas mx-2">
                    <a class="textFontDidact " routerLink="/Life-Control-Channel" title="Life Control Channel">Leer
                        más</a>
                </div>
                <div class="row lineSeeDee res">
                    <hr class="lineSee f1 mr-md-2 mr-1">
                    <hr class="lineSee f2 mr-md-2 mr-1">
                    <hr class="lineSee f3 mr-md-2 mr-1">
                    <hr class="lineSee f4 mr-md-2 mr-1">
                </div>
            </div>
        </div>

    </div>
    <!-- Texto LCC -->
    <div class="col-12 colorBg contService txtAfterBanner">
        <div data-wow-delay="0.5s" class="wow animate__flipInX txtColor1 contService">
            <h1 class="m-0 lhe textUpLine textFontAnthon ">CREAMOS EXPERIENCIAS ÚNICAS</h1>
            <hr class="line  lhe">
            <h5 class="m-0 lhe textDownLine textFontDidact ">En LCC S.A.S. somos creadores de contenidos digitales para
                redes sociales,
                administramos la visualización de su marca con el objetivo de potencializar su presencia en el mundo
                digital. Por medio de nuestros paquetes de servicio, usted logrará iniciar su producción de imágenes,
                reels, post, videos cortos, entre otros, con el fin de conectar a su empresa con su audiencia. Le
                asesoramos en las mejores prácticas de administrar sus redes sociales y crear contenido de valor para
                atraer a esos clientes que usted tanto desea.</h5>
        </div>
    </div>
    <!-- Banner Multiplex -->
    <div data-wow-delay="0.3s "
        class=" wow animate__fadeIn animate__slow backImg bannerFather multiplexBanner contService">
        <div class="contService">
            <div class="row contService fatherTxt">

                <!-- <div data-wow-delay="0.5s" class="wow animate__fadeInBottomLeft txtBanner txtMultiplex mrTxtBanner">MULTIPLE<span class="letterLast">X</span></div> -->
                <div data-wow-delay="1s" class="wow animate__fadeIn txtBanner txtEduplexBS mrTxtBanner">EDUPLEXBS</div>
                <!-- <div data-wow-delay="0.5s" class="wow animate__fadeInTopRight txtBanner txtSchool">SCHOO<span class="letterLast">L</span></div> -->

            </div>

            <div data-wow-delay="0.5s" class=" wow animate__bounceIn animate__slow textWhite row contService">
                <div class="row lineSeeDee front">
                    <hr class="lineSee f4 ml-md-2 ml-1">
                    <hr class="lineSee f3 ml-md-2 ml-1">
                    <hr class="lineSee f2 ml-md-2 ml-1">
                    <hr class="lineSee f1 ml-md-2 ml-1">
                </div>
                <div class="buttonVerMas mx-2">
                    <a class="textFontDidact" href="#" onclick="return false;">Leer más</a>
                </div>
                <div class="row lineSeeDee res">
                    <hr class="lineSee f1 mr-md-2 mr-1">
                    <hr class="lineSee f2 mr-md-2 mr-1">
                    <hr class="lineSee f3 mr-md-2 mr-1">
                    <hr class="lineSee f4 mr-md-2 mr-1">
                </div>
            </div>
        </div>
    </div>
    <!-- Texto Multiplex -->
    <div class="col-12 colorBg contService txtAfterBanner">
        <div data-wow-delay="0.5s" class="wow animate__flipInX txtColor1 contService">
            <h1 class="m-0 lhe textUpLine textFontAnthon ">EL CONOCIMIENTO ES LA PUERTA DEL ÉXITO</h1>
            <hr class=" line lhe">
            <h5 class="m-0 lhe textDownLine textFontDidact ">EduplexBS es nuestra metodología de apropiación del
                conocimiento empresarial, trabajamos 100% de manera virtual y remota con el objetivo de alcanzar
                empresas en diferentes áreas de Latinoamérica.
                Nuestro método cuenta con instructores especializados por cada área que constantemente están creando
                contenido digital educativo en áreas como lo son: las finanzas, el diseño, la publicidad digital, y
                especialmente en herramientas de trabajo como lo es la hoja de cálculo Excel. EduplexBS es un servicio
                especial de Life Control Channel S.A.S.</h5>
        </div>
    </div>

    <div id="blog" class=" carrusel bgTextura p-4 ">
        <div style="padding-left: 8%;padding-right: 8%; " class=" ">
            <h5 class="wow animate__lightSpeedInLeft textFontDidact textTitleCarr aText1 text-left "><b
                    class="textFontAnthon fz10 ">BLOG &nbsp; &nbsp;</b><span class="letterSpa ">----------------</span>
                &nbsp; &nbsp;<span class="textFontDidact ">Artículos que te pueden interesar</span></h5>
            <owl-carousel-o [options]="customOptions" class=" ">
                <ng-container *ngFor="let dataOps of data; index as i">
                    <ng-template carouselSlide>
                        <div data-wow-delay="0.3s" id="slideArt "
                            class="wow animate__fadeIn animate__slow p-1 heightArt  ban col-lg-12 col-md-12 slideArt ">
                            <div class="slideArtIn box relative ">
                                <div class="imgMxBlog ">
                                    <img class="img-fluid " width="100% " src="assets/imgArticulos/{{dataOps.img}}.jpg "
                                        alt=" ">
                                </div>
                                <div (click)="changeFragment('Articulos','art'+(i+1))"
                                    class="hvArticulos heightTextArt">
                                    <div class="left textFontDidact ">
                                        <h6 class="autorArticle1">{{dataOps.artTitle}}</h6>
                                        <h6 class="autorArticle">{{dataOps.postedBy}}</h6>
                                        <h6 class="dateArticle">{{dataOps.datePost}}</h6>
                                    </div>
                                    <div class="dtl contService textFontAnthon ">
                                        <a class="w-100 " href="#" onclick="return false;">
                                            <h1 class="float-left fontSizeL ">Leer más</h1> <i
                                                class="fa fa-arrow-right fontSizeL float-right "></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>

    <!-- Publicidad Variada Formulario Contactenos -->
    <div id="lanzamientos"
        class="wow animate__lightSpeedInLeft bannerPublicidad p-0 backImg heDiv contServicePublicidad  p-0 ">
        <div class="bp alc al-l p-1 p-md-5">

            <div id="" class="text-white pl-5">
                <p data-wow-delay="0.9s" class=" wow animate__fadeInTopRight princText  ">EXCEL PARA LA</p>
                <p data-wow-delay="0.9s" class=" wow animate__fadeInTopRight princText  ">GESTIÓN DE</p>
                <p data-wow-delay="0.9s" class=" wow animate__fadeInTopRight princText  ">DATOS</p>
                <p data-wow-delay="0.7s" class=" wow animate__fadeInTopLeft subText ">CURSO EMPRESARIAL</p>

            </div>
        </div>
    </div>
</section>