<!-- Banner Principal -->
<section class="sectionPrincipa">
    <div class="p-0 bannerPrLc wow  animate__backInUp">
        <img class="img-gluid noSelect" src="assets/Lcc/Banner-Lcc.jpg" alt="">
    </div>
    <!-- Video Principal-->
    <div class="w-100 textSection textFontDidact alc al-c px-md-5 px-2 ">
        <h5 class="px-md-5 px-2">En LCC S.A.S. somos creadores de contenidos digitales para redes sociales,
            administramos la
            visualización de
            su marca con el objetivo de potencializar su presencia en el mundo digital. Por medio de nuestros paquetes
            de servicio, usted logrará iniciar su producción de imágenes,
            reels, post, videos cortos, entre otros, con el fin de conectar a su empresa con su audiencia.
            Le asesoramos en las mejores prácticas de administrar sus redes sociales y crear contenido de valor para
            atraer a esos clientes que usted tanto desea.</h5>
    </div>

    <!-- inicio de los objetos de servicios -->
    <div class="service w-100">
        <div class="contentManager">
            <!-- inicio de los objetos del fondo -->
            <div class="contentBack ">
                <div class="wow animate__lightSpeedInRight backG backGray1">
                    <div class="boxGray float-right"></div>
                </div>
                <div class="wow animate__lightSpeedInRight textBack textBack-1">
                    <h3 class="txtBanner float-right">DIGITAL</h3>
                </div>
                <div class="wow animate__lightSpeedInLeft backG backGray2">
                    <div class="boxGray"></div>
                </div>
                <div class="wow animate__lightSpeedInLeft textBack textBack-2">
                    <h3 class="txtBanner float-left">DISEÑO</h3>
                </div>
                <div class="wow animate__lightSpeedInRight backG backGray3">
                    <div class="boxGray float-right"></div>
                </div>
            </div>
            <!-- fin de los objetos del fondo -->
            <!-- inicio de los objetos delanteros -->
            <div class="w-100">
                <!-- Seccion del primer bloque de ventanas modales de servicios  -->
                <div class="w-100 marginSection">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="titleItem titleAling">
                                <h3 data-wow-delay="0.4s" class="wow animate__fadeInLeftBig">Contenidos</h3>
                                <hr data-wow-delay="0.2s" class="wow animate__fadeInLeftBig">
                                <h3 data-wow-delay="0.0s" class="wow animate__fadeInLeftBig">Digitales corporativos</h3>
                            </div>
                        </div>
                        <div data-wow-delay="0.2s" class="wow animate__fadeIn col-12 col-lg-6 imgItemService">
                            <img class="img-fluid" src="assets/imgArticulos/art1.jpg" alt="">
                        </div>
                        <div class="col-12 col-lg-6 contService">
                            <div class="w-100">
                                <div [attr.data-wow-delay]="'0.'+ i + 's'"
                                    *ngFor="let item of contenidoValor; index as i"
                                    class="wow animate__fadeIn row w-100 itemContenidoValor">
                                    <div [ngClass]="{'opacityHrDiv': i % 2 == 0}" [innerHTML]="hrLeft"
                                        class="col-4 col-md-2  alingHrLeft order-3 order-lg-0"></div>
                                    <div class="col-12 col-md-6  p-0 order-0 order-lg-1 contService">
                                        <div class="txtItem w-100">
                                            <p class="textFontAnthon">{{item.title}}</p>
                                            <p class="textFontDidact">{{item.subTitle}}</p>
                                        </div>
                                    </div>
                                    <!-- Parte para el PDF -->
                                    <div [ngClass]="{'opacityHrDiv': ! (i % 2 == 0)}" [innerHTML]="hrRight"
                                        class="col-4 col-md-2  alingHrRight order-lg-2"></div>
                                    <div class="col-4 col-md-2 p-0 contService order-lg-3">
                                        <div class="seeMore w-100 wow animate__bounceIn animate__slow">
                                            <button (click)="downloadPDF()">Leer más</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-100 p-3">
                    <div class="row  p-0">
                        <div class="col-lg-12">
                            <div class="titleItem titleAling">
                                <h3 data-wow-delay="0.2s" class="wow animate__fadeInLeftBig">EJEMPLOS</h3>
                                <hr data-wow-delay="0.0s" class="wow animate__fadeInLeftBig">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 contService itemGalery">
                            <div class="col-12 col-md-10 col-lg-8">
                                <div data-wow-delay="0.3s" class="wow animate__fadeIn imgGaleria">
                                    <img src="assets/imgArticulos/art1.jpg" alt="">
                                </div>
                                <div class="wow animate__lightSpeedInLeft txtGaleria contService">
                                    <div>
                                        <div class="w-100 title">
                                            <h6 class="textFontAnthon">GALERÍA DE FOTOS</h6>
                                        </div>
                                        <div class="row w-100 contService">
                                            <div [innerHTML]="hrRight" class=" p-0 contService"></div>
                                            <div class="seeMoreGallery">
                                                <button (click)="openModal('carousel', 'lccGalery')">Leer más</button>
                                            </div>
                                            <div [innerHTML]="hrLeft" class="p-0 contService"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 contService itemGalery">
                            <div class="col-12 col-md-10 col-lg-8">
                                <div data-wow-delay="0.3s" class="wow animate__fadeIn imgGaleria">
                                    <img src="assets/imgArticulos/art1.jpg" alt="">
                                </div>
                                <div class="wow animate__lightSpeedInRight txtGaleria contService ">
                                    <div>
                                        <div class="w-100 title">
                                            <h6 class="textFontAnthon">GALERÍA DE VIDEOS</h6>
                                        </div>
                                        <div class="row w-100 contService">
                                            <div [innerHTML]="hrRight" class=" p-0 contService"></div>
                                            <div class="seeMoreGallery">
                                                <button (click)="openModal('carousel', 'vid')">Leer más</button>
                                            </div>
                                            <div [innerHTML]="hrLeft" class="p-0 contService"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- fin de los objetos del delanteros -->
        </div>
    </div>
    <!-- fin de los objetos de servicios -->
</section>