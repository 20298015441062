import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes, ExtraOptions } from "@angular/router";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { CarouselModule } from "ngx-owl-carousel-o";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgwWowModule } from "ngx-wow";

import { ArticulosSectionComponent } from "./components/articulos-section/articulos-section.component";
import { LccSectionComponent } from "./components/lcc-section/lcc-section.component";
import { HeSectionComponent } from "./components/he-section/he-section.component";
import { HomeComponent } from "./components/home/home.component";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { ModalComponent } from "./shared/modal/modal.component";
import { CarouselComponent } from "./shared/carousel/carousel.component";
import { TeamSectionComponent } from "./components/team-section/team-section.component";
import { FilosofiaSectionComponent } from "./components/filosofia-section/filosofia-section.component";
import { LanzamientosSectionComponent } from "./components/lanzamientos-section/lanzamientos-section.component";
import { TerminosComponent } from "./components/terminos/terminos.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 60],
  useHash: true,
};

// tslint:disable: max-line-length
const routes: Routes = [
  { path: "", redirectTo: "/Home", pathMatch: "full" },
  { path: "Home", component: HomeComponent },
  { path: "He-Consultores", component: HeSectionComponent },
  { path: "Life-Control-Channel", component: LccSectionComponent },
  { path: "Articulos", component: ArticulosSectionComponent },
  { path: "Equipo", component: TeamSectionComponent },
  { path: "Filosofia", component: FilosofiaSectionComponent },
  { path: "Lanzamientos", component: LanzamientosSectionComponent },
  { path: "terminos", component: TerminosComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    CarouselComponent,
    HomeComponent,
    HeSectionComponent,
    LccSectionComponent,
    ArticulosSectionComponent,
    TeamSectionComponent,
    FilosofiaSectionComponent,
    LanzamientosSectionComponent,
    TerminosComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgwWowModule,
    CarouselModule,
    RouterModule.forRoot(routes, routerOptions),
    FormsModule,
    HttpClientModule,
    MatDialogModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
