import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Articulo1, Articulo2, Articulo3, Articulo4, Articulo5, Articulo6 } from '../../class/articulos';
import { NavService } from 'src/app/services/nav.service';


@Component({
  selector: 'app-articulos-section',
  templateUrl: './articulos-section.component.html',
  styleUrls: ['./articulos-section.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ArticulosSectionComponent implements OnInit {
  dataArt = [];
  articulo1: any;
  articulo2: any;
  articulo3: any;
  articulo4: any;
  articulo5: any;
  articulo6: any;

  constructor(private sanitizer: DomSanitizer, private navService: NavService) {
    this.articulo1 = this.sanitizer.bypassSecurityTrustHtml(Articulo1);
    this.articulo2 = this.sanitizer.bypassSecurityTrustHtml(Articulo2);
    this.articulo3 = this.sanitizer.bypassSecurityTrustHtml(Articulo3);
    this.articulo4 = this.sanitizer.bypassSecurityTrustHtml(Articulo4);
    this.articulo5 = this.sanitizer.bypassSecurityTrustHtml(Articulo5);
    this.articulo6 = this.sanitizer.bypassSecurityTrustHtml(Articulo6);
    this.cargarDataArt();
  }

  ngOnInit(): void {
  }
  changeFragment(path: string, fragment: string) {
    this.navService.changePos(path, fragment);
  }
  redSocialLink(link: string) {
    console.log(link);
  }

  cargarDataArt() {
    this.dataArt = [
      {
        titleArt: 'Los problemas del posicionamiento de marca en el mundo digital.',
        imgArt:   'art1',
        autorArt: 'Daniela Buraglia',
        workAuthorArt: 'CO-FUNDADORA Y DIRECTORA DE PRODUCCIÓN DE CONTENIDOS',
        dateArt:  'marzo 2020',
        dateArtComplete:  '10 de marzo del 2020',
        categoryArt: 'Tegnologia',
        content: this.articulo1
      },
      {
        titleArt: 'Branding, todos lo hablan y nadie lo sabe.',
        imgArt:   'art2',
        autorArt: 'hans emil',
        workAuthorArt: 'FUNDADOR Y DIRECTOR GENERAL LIFE CONTROL CHANNEL',
        dateArt:  'marzo 2020',
        dateArtComplete:  '10 de marzo del 2020',
        categoryArt: 'Tegnologia',
        content: this.articulo2
      },
      {
        titleArt: 'No pagues por titulos, paga por conocimientos.',
        imgArt:   'art3',
        autorArt: 'hans emil',
        workAuthorArt: 'FUNDADOR Y DIRECTOR GENERAL LIFE CONTROL CHANNEL',
        dateArt:  'marzo 2020',
        dateArtComplete:  '10 de marzo del 2020',
        categoryArt: 'Tegnologia',
        content: this.articulo3
      },
      {
        titleArt: 'Emprendimiento no emprendimiento.',
        imgArt:   'art4',
        autorArt: 'hans emil',
        workAuthorArt: 'FUNDADOR Y DIRECTOR GENERAL LIFE CONTROL CHANNEL',
        dateArt:  'marzo 2020',
        dateArtComplete:  '10 de marzo del 2020',
        categoryArt: 'Tegnologia',
        content: this.articulo4
      },
      {
        titleArt: 'Ventas Con redes sociales, ¿Funcionan o no?.',
        imgArt:   'art5',
        autorArt: 'hans emil',
        workAuthorArt: 'FUNDADOR Y DIRECTOR GENERAL LIFE CONTROL CHANNEL',
        dateArt:  'marzo 2020',
        dateArtComplete:  '10 de marzo del 2020',
        categoryArt: 'Tegnologia',
        content: this.articulo5
      },
      {
        titleArt: 'Sin información no hay gestión.',
        imgArt:   'art6',
        autorArt: 'hans emil',
        workAuthorArt: 'FUNDADOR Y DIRECTOR GENERAL LIFE CONTROL CHANNEL',
        dateArt:  'marzo 2020',
        dateArtComplete:  '10 de marzo del 2020',
        categoryArt: 'Tegnologia',
        content: this.articulo6
      },
    ];
  }

}
