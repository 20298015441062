<owl-carousel-o [options]="customOptions " class=" ">
    <ng-container *ngFor="let dataOps of data">
        <ng-template carouselSlide>
            <div class="p-2 wow fadeIn col-lg-12 col-md-12 ">
                <div class="carouselItem box relative ">
                    <div *ngIf="typeContent === 'img'" class="contentCarrousel">
                        <img width="100% " src="assets/imgArticulos/{{dataOps.content}}.jpg " alt=" ">
                    </div>
                    <div *ngIf="typeContent === 'lccGalery'" class="contentCarrousel">
                        <img width="100% " src="assets/Lcc/serviceModal/Galleria/{{dataOps.titleContent}}" alt=" ">
                    </div>
                    <div *ngIf="typeContent === 'vid'" class="contentCarrousel vid">
                        <video id="my_video_1" class="video-js vjs-default-skin" controls preload="none"
                            poster='assets/imgArticulos/art1.jpg' data-setup='{ "playbackRates": [1, 1.5, 2] }'>
                            <source src="assets/Lcc/videos/{{dataOps.content}}.mp4" type='video/mp4' />
                        </video>
                    </div>
                    <div class="contService w-100 heightTextArt">
                        <h6 class="textFontAnthon">{{dataOps.titleContent}}</h6>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>