import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavService } from "src/app/services/nav.service";
import { ModalService } from "./../../services/modal.service";
import { ContentService } from "./../../services/content.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  data = [];
  businesServices = [];
  customOptions: any;

  videoSource: string;

  constructor(
    private navService: NavService,
    private modal: ModalService,
    private contentHtml: ContentService
  ) {}

  ngOnInit() {
    this.cargarData();
  }

  ngOnDestroy() {}

  changeFragment(path: string, fragment: string) {
    this.navService.changePos(path, fragment);
  }

  openModal(type: string, content: string) {
    this.modal.openDialog(type, content);
  }
  startDataItemServ() {
    this.businesServices = [
      {
        typeModal: "servicioHe",
        contentModal: "service2",
        title: "CONSULTORÍA FINANCIERA Y DE NEGOCIOS",
        subTitle: "Tratamiento financiero (ADT)",
      },
    ];
  }

  cargarData() {
    this.customOptions = {};
    this.customOptions = {
      loop: true,
      center: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 1500,
      autoplay: false,
      autoplayHoverPause: true,
      autoplaySpeed: 1500,
      dotsSpeed: 1500,
      navText: ["", ""],
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 3,
        },
      },
      nav: true,
    };
    this.data = [];
    this.data = [
      {
        artTitle: "Emprendimiento no emprendimiento.",
        postedBy: "Hans Emil",
        datePost: "15-03-2020",
        img: "art4",
      },
      {
        artTitle: "Ventas Con redes sociales, ¿Funcionan o no?.",
        postedBy: "Hans Emil",
        datePost: "15-03-2020",
        img: "art5",
      },
      {
        artTitle: "Sin información no hay gestión.",
        postedBy: "Hans Emil",
        datePost: "15-03-2020",
        img: "art6",
      },
    ];
  }
}
