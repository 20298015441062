<div class="divContentMenu1  contService animate__animated slow animate__fadeIn">
    <div (click)="openMenu()" class="menuOpen">
        <a class="textFontAnthon textMenu contService" onclick="return false;">
            <span class="w-100">Menú</span>
            <i class="fa fa-arrow-right"></i>
        </a>
    </div>
    <div class="toggle_switch">
        <input id="btnTheme" (click)="toggleTheme()" type="checkbox" class="switch_3">
        <svg class="checkbox" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 80">
            <path class="outer-ring"
                d="M41.534 9h88.932c17.51 0 31.724 13.658 31.724 30.482 0 16.823-14.215 30.48-31.724 30.48H41.534c-17.51 0-31.724-13.657-31.724-30.48C9.81 22.658 24.025 9 41.534 9z"
                fill="none" stroke="#233043" stroke-width="3" stroke-linecap="square" stroke-miterlimit="3" />
            <path class="is_checked"
                d="M132.77 22.348c7.705 10.695 5.286 25.617-5.417 33.327-2.567 1.85-5.38 3.116-8.288 3.812 7.977 5.03 18.54 5.024 26.668-.83 10.695-7.706 13.122-22.634 5.418-33.33-5.855-8.127-15.88-11.474-25.04-9.23 2.538 1.582 4.806 3.676 6.66 6.25z" />
            <path class="is_unchecked"
                d="M17 39.482c0-12.694 10.306-23 23-23s23 10.306 23 23-10.306 23-23 23-23-10.306-23-23z" />
        </svg>
        <div class="slider"></div>
    </div>
    <div class="btn-group btnTraduccion" role="group">
        <button style="border-right: solid 1px white;" type="button" class="btnEs ">Es</button>
        <button type="button" class="btnEn">En</button>
    </div>
</div>


<div id="menuLcc" style=" z-index:20;" class="overlay-navigation">
    <i (click)="openMenu()" class="btnArrowLeft fa fa-arrow-left"></i>
    <i (click)="openMenu()" class="btnClose fa fa-times"></i>
    <nav>
        <div class="h-100 contService">
            <ul class=" textFontAnthon">
                <li class=" textFontAnthon contService">
                    <div *ngIf="!(urlActual == 'Home')" class="contService divLinkA">
                        <a class="aText1" (click)="changeFragment('Home', '')" href="#" onclick="return false;">home</a>
                    </div>
                    <div *ngIf="!(urlActual == 'Equipo')" class="contService divLinkA">
                        <a class="aText1 " (click)="changeFragment('Equipo', '')" href="#"
                            onclick="return false;">equipo</a>
                    </div>
                    <div *ngIf="!(urlActual == 'Filosofia')" class="contService divLinkA">
                        <a class="aText1 " (click)="changeFragment('Filosofia', '')" href="#"
                            onclick="return false;">filosofia</a>
                    </div>
                </li>
                <li class=" textFontAnthon bg-2 contService">
                    <div *ngIf="!(urlActual == 'Life-Control-Channel')" class="contService divLinkA">
                        <a class="aText2 " (click)="changeFragment('Life-Control-Channel', '')" href="#"
                            onclick="return false;">life control channel</a>
                    </div>
                    <div *ngIf="!(urlActual == 'Consultores')" class="contService divLinkA">
                        <a class="aText2 " (click)="changeFragment('He-Consultores', '')" href="#"
                            onclick="return false;">consultores</a>
                    </div>
                    <div class="contService divLinkA">
                        <a class="aText2 " (click)="changeFragment('Home', '')" href="#" onclick="return false;">EDUPLEX
                            BS</a>
                    </div>
                </li>
                <li class=" textFontAnthon contService">
                    <div *ngIf="!(urlActual == 'Lanzamientos')" class="contService divLinkA">
                        <a class="aText1 " (click)="changeFragment('Lanzamientos', '')" href="#"
                            onclick="return false;">lanzamientos</a>
                    </div>
                    <div class="contService divLinkA">
                        <a class="aText1 " (click)="changeFragment('Home', 'blog')" href="#"
                            onclick="return false;">blog</a>
                    </div>
                    <div class="contService divLinkA">
                        <a class="aText1 " (click)="changeFragment('', 'hablemos')" href="#"
                            onclick="return false;">hablemos</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>