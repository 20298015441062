import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filosofia-section',
  templateUrl: './filosofia-section.component.html',
  styleUrls: ['./filosofia-section.component.css']
})
export class FilosofiaSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
