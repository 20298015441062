<section class="sectionArticulos bg-1">
    <div *ngFor="let item of dataArt; index as i" class="artFather">
        <div id="art{{i+1}}" class="contenedorArticulo w-100 contService">
            <img class="imgArt" src="assets/imgArticulos/{{item.imgArt}}.jpg" alt="">
            <div class=" row px-0 pt-5">
                <div class=" col-12 col-lg-3 p-0 order-1 order-lg-0">
                    <div class="row p-2 w-100 m-0">
                        <div *ngFor="let data of dataArt; index as i" class="col-6 col-sm-4 col-lg-12 p-1 mb-2  ">
                            <div class="cardArt">
                                <img class="imgArtLink" src="assets/imgArticulos/{{data.imgArt}}.jpg" alt="">


                                <a (click)="changeFragment('Articulos','art'+(i+1))" class="linkContent" href="#" onclick="return false;">
                                    <h6 class="titleArt">{{data.titleArt}}</h6>
                                    <h6 class="numberArt">#{{i + 1}}</h6>
                                    <h6 class="autorArt">{{data.autorArt}}</h6>
                                    <h6 class="dateArt">{{data.dateArt}}</h6>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="txtArt col-lg-9">
                    <div class="contTextArt">
                        <div class="categoriaArt">
                            <div class="row contService1 m-0">
                                <div class="item1">
                                    <h6>Categoria</h6>
                                </div>
                                <div class="item2">
                                    <hr>
                                </div>
                                <div class="item3">
                                    <h4>{{item.categoryArt}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="textA">
                            <div class="titleArtTxt">
                                {{item.titleArt}}
                            </div>
                            <div class="authorArtDiv row m-0">
                                <div class="author col-12 col-lg-6 p-0 order-0">escrito por {{item.autorArt}}</div>
                                <div class="publicationDate col-12 col-lg-6 p-0 order-2 order-lg-1">PUBLICADO EL {{item.dateArtComplete}}</div>
                                <div class="workAuthor col-12 p-0 order-1 order-lg-2">{{item.workAuthorArt}}</div>
                            </div>
                            <div class="lineHr ">
                                <hr>
                            </div>
                            <div [innerHTML]="item.content" class="articuloTxt">

                            </div>
                            <div class="lineHr">
                                <hr>
                            </div>
                            <div class="redesSociales contService mb-5">
                                <a href="#" onclick="return false;">
                                    <p class="btnRedSocial contService">
                                        <i class="fa fa-facebook" aria-hidden="true"></i>
                                    </p>
                                </a>
                                <a href="#" onclick="return false;">
                                    <p class="btnRedSocial contService">
                                        <i class="fa fa-youtube" aria-hidden="true"></i>
                                    </p>
                                </a>
                                <a href="#" onclick="return false;">
                                    <p class="btnRedSocial contService">
                                        <i class="fa fa-instagram" aria-hidden="true"></i>
                                    </p>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <ul class="ulLine">
    <li>¿Las operaciones de ventas son recurrentes?</li>
    <li>¿Cuento con una estructura financiera definida?</li>
    <li>¿Tengo formalizada la contabilidad de la empresa?</li>
    <li>¿Se pagan impuestos?</li>
    <li>¿Existen roles y funciones determinadas?</li>
    <li>¿Se manejan estrategias de branding?</li>
    <li>¿El crecimiento anual es mayor a dos dígitos?</li>
    <li>¿Puedo como fundador o propietario salirme de la operación y todo funciona?</li>
    <li>¿Se cuenta con expansión física o digital?</li>
    <li>¿Hay procesos claros de producción?</li>
</ul> -->