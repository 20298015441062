import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { ContentService } from "./../../services/content.service";
import { ModalService } from "./../../services/modal.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-lcc-section",
  templateUrl: "./lcc-section.component.html",
  styleUrls: ["./lcc-section.component.css"],

})
export class LccSectionComponent implements OnInit {
  constructor(
    private modal: ModalService,
    private contentHtml: ContentService,
    private http: HttpClient
  ) {}

  hrRight = this.contentHtml.hrRight;
  hrLeft = this.contentHtml.hrLeft;

  contenidoValor = [];
  contenidoDigital = [];

  ngOnInit(): void {
    this.startDataItemServ();
  }

  downloadPDF() {
    const url = "assets/BROCHUREFINANCIERO.pdf";
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = "brochure_financiero.pdf";
    // link.click();
    window.open(url);
  }
  openModal(type: string, content: string) {
    this.modal.openDialog(type, content);
  }

  startDataItemServ() {
    this.contenidoValor = [
      {
        typeModal: "servicioLcc",
        contentModal: "service1",
        title: "ASESORÍA EN ADMINISTRACIÓN DE",
        subTitle: "PERFILES DIGITALES",
      },
      {
        typeModal: "servicioLcc",
        contentModal: "service2",
        title: "SERVICIO DE ADMINISTRACIÓN DE",
        subTitle: "REDES SOCIALES",
      },
      {
        typeModal: "servicioLcc",
        contentModal: "service3",
        title: "DISEÑO, DESARROLLO",
        subTitle: "DE SITIOS WEB",
      },
    ];
    this.contenidoDigital = [
      {
        title: "TOMA DE FOTOGRAFIA",
        subTitle: "y Edición",
        img: "art1.jpg",
      },
      {
        title: "TOMA DE FOTOGRAFIA",
        subTitle: "y Edición",
        img: "art1.jpg",
      },
      {
        title: "TOMA DE FOTOGRAFIA",
        subTitle: "y Edición",
        img: "art1.jpg",
      },
    ];
  }
}
