<section id="filosofia">
    <!-- //* Inicio Del Banner Principal  *// -->

    <div class="wow  animate__backInUp col-12 heDiv banner-filosofia">
        <div>

        </div>
    </div>
    <!-- //* Fin Del Banner Principal  *// -->
    <div class="divFilosofia">
        <div class=" imgBackFilosofia"></div>
        <div class=" divFilosofiaDegrad"></div>


        <div class="container py-4 DivTxtFilosofia wow animate__lightSpeedInLeft txtFilosofia textFontDidact">
            <h1>Nuestra Filosofía de Negocio</h1>
            <p>Nuestra filosofía de negocio se basa en principios fundamentales que nos guían en nuestra misión de ser
                socios
                estratégicos y facilitadores del éxito para nuestros clientes por medio de nuestra modalidad de
                consultoría
                instructiva. En Life Control Channel S.A.S. creemos que, al seguir principios basados en la educación y
                la calidad
                de nuestros servicios, podemos proporcionar un valor excepcional y contribuir al crecimiento sostenible
                de las
                empresas que confían en nuestros servicios de consultoría empresarial.</p>

            <h2>Giramos alrededor de:</h2>
            <ul>
                <li><strong>Orientación al cliente:</strong> Nuestros clientes son la piedra angular de nuestro negocio,
                    y su éxito
                    es nuestra máxima prioridad.</li>

                <li><strong>Enfoque:</strong> A través de un enfoque colaborativo, desarrollamos soluciones a medida que
                    abordan sus
                    problemas y desbloquean su potencial de crecimiento, abriendo la mente corporativa y viendo una
                    forma diferente
                    de fluir en los negocios en una sociedad cada vez más digital.</li>

                <li><strong>Atención:</strong> Nos comprometemos a escuchar atentamente sus necesidades, desafíos y
                    objetivos
                    específicos para proporcionar soluciones altamente personalizadas y adaptadas a sus circunstancias
                    únicas.</li>

                <li><strong>Calidad humana:</strong> Nuestro equipo de trabajo en LCC S.A.S. se presenta ante sus
                    clientes como un
                    equipo con valores morales, éticos y profesionales con lo que busca establecer una relación de largo
                    plazo con
                    nuestros clientes.</li>
            </ul>

        </div>
        <div class="container py-4 mb-5">
            <div class="col-lg-12 contService">
                <div class="row p-0 w-100 contService">
                    <div class="p-1 col-12 col-md-6 col-lg-3">
                        <div class="p-1">
                            <div class="wow animate__lightSpeedInLeft mb-4">
                                <img class="img-fluid " src="assets/imgArticulos/art1.jpg " alt=" ">
                            </div>
                            <div data-wow-delay="0.2s" class="wow animate__lightSpeedInLeft contService">
                                <div class="w-100 ">
                                    <h6 class="fontTitle txtColor1 textFontAnthon">Orientación al Cliente</h6>
                                    <!-- <h6 class="fontSubTitle txtColor1 textFontDidact">De piezas para eventos</h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-1 col-12 col-md-6 col-lg-3 order-1 order-md-2 order-lg-1">
                        <div class="p-1">
                            <div class="wow animate__lightSpeedInLeft mb-4">
                                <img class="img-fluid " src="assets/imgArticulos/art1.jpg " alt=" ">
                            </div>
                            <div data-wow-delay="0.2s" class="wow animate__lightSpeedInLeft contService">
                                <div class="w-100 ">
                                    <h6 class="fontTitle txtColor1 textFontAnthon">Enfoque</h6>
                                    <!-- <h6 class="fontSubTitle txtColor1 textFontDidact">De piezas para eventos</h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-1 col-12 col-md-6 col-lg-3 order-2 order-md-1 order-lg-2">
                        <div class="p-1">
                            <div class="wow animate__lightSpeedInRight mb-4">
                                <img class="img-fluid " src="assets/imgArticulos/art1.jpg " alt=" ">
                            </div>
                            <div data-wow-delay="0.2s" class="wow animate__lightSpeedInRight contService">
                                <div class="w-100 ">
                                    <h6 class="fontTitle txtColor1 textFontAnthon">Calidad Humana
                                    </h6>
                                    <!-- <h6 class="fontSubTitle txtColor1 textFontDidact">De piezas para eventos</h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-1 col-12 col-md-6 col-lg-3 order-2 order-md-1 order-lg-2">
                        <div class="p-1">
                            <div class="wow animate__lightSpeedInRight mb-4">
                                <img class="img-fluid " src="assets/imgArticulos/art1.jpg " alt=" ">
                            </div>
                            <div data-wow-delay="0.2s" class="wow animate__lightSpeedInRight contService">
                                <div class="w-100 ">
                                    <h6 class="fontTitle txtColor1 textFontAnthon">Atención
                                    </h6>
                                    <!-- <h6 class="fontSubTitle txtColor1 textFontDidact">De piezas para eventos</h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>