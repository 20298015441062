<!-- Banner Principal -->
<section class="sectionPrincipa">
    <div class="p-0  banner-consultores wow  animate__backInUp">

    </div>
    <!-- Video Principal-->
    <div id="videoPrincipal" class="w-100 contServiceS alc al-c">
        <h5 class="m-0 lhe textDownLine textFontDidact ">La consultoría que realizamos está enfocada en mejorar las
            prácticas empresariales,
            por esta razón nuestra consultoría es un asesoramiento y acompañamiento basados en la educación empresarial,
            lo cual hemos denominado “Consultoría Instructiva”, nuestro objetivo es enseñar e implementar las
            herramientas a la empresa con una manera disruptiva, eficiente y directa en programas especiales para su
            empresa.</h5>

    </div>

    <!-- inicio de los objetos de servicios -->
    <div class="service w-100">
        <div class="contentManager">
            <!-- inicio de los objetos del fondo -->
            <div class="contentBack ">

                <div class="wow animate__lightSpeedInRight textBack textBack-1">
                    <h3 class="txtBanner float-right">ASESORÍA</h3>
                </div>


                <div class="wow animate__lightSpeedInLeft textBack textBack-2">
                    <h3 class="txtBanner float-left">ANÁLISIS</h3>
                </div>
                <!-- <div class="wow animate__lightSpeedInRight backG backGray3">
                    <div class="boxGray float-right"></div>
                </div> -->
            </div>
            <!-- fin de los objetos del fondo -->
            <!-- inicio de los objetos delanteros -->
            <div style="height: 100%;" class="w-100 alc al-c">
                <!-- Seccion del primer bloque de ventanas modales de servicios  -->
                <div class="w-100 marginSection">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="titleItem titleAling">
                                <h3 data-wow-delay="0.4s" class="wow animate__fadeInLeftBig">Nuestros Servicios</h3>
                                <hr data-wow-delay="0.2s" class="wow animate__fadeInLeftBig">
                                <h3 data-wow-delay="0.0s" class="wow animate__fadeInLeftBig">Empresariales</h3>
                            </div>
                        </div>
                        <div data-wow-delay="0.2s" class="wow animate__fadeIn col-12 col-lg-6 imgItemService">
                            <img class="img-fluid" src="assets/imgArticulos/art1.jpg" alt="">
                        </div>
                        <div class="col-12 col-lg-6 contService">
                            <div class="w-100">
                                <div [attr.data-wow-delay]="'0.'+ i + 's'"
                                    *ngFor="let item of businesServices; index as i"
                                    class="wow animate__fadeIn row w-100 itemContenidoHE">
                                    <div [ngClass]="{'opacityHrDiv': i % 2 == 0}" [innerHTML]="hrLeft"
                                        class="col-4 col-md-2  alingHrLeft order-3 order-lg-0"></div>
                                    <div class="col-12 col-md-6  p-0 order-0 order-lg-1 contService">
                                        <div class="txtItem w-100">
                                            <p class="textFontAnthon">{{item.title}}</p>
                                            <p class="textFontDidact">{{item.subTitle}}</p>
                                        </div>
                                    </div>
                                    <div [ngClass]="{'opacityHrDiv': ! (i % 2 == 0)}" [innerHTML]="hrRight"
                                        class="col-4 col-md-2  alingHrRight order-lg-2"></div>
                                    <div class="col-4 col-md-2 p-0 contService order-lg-3">
                                        <div class="wow animate__bounceIn animate__slow seeMore w-100">
                                            <button (click)="downloadPDF(item.brochure)">Leer
                                                más</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- fin de la seccion del primer bloque de ventanas modales de servicios  -->

                <!-- Seccion del segundo bloque de ventanas modales de servicios  -->
                <!-- <div class="w-100 marginSection">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="titleItem titleAling2">
                                <h3 data-wow-delay="0.0s" class="wow animate__fadeInRightBig">Consultorias En</h3>
                                <hr data-wow-delay="0.2s" class="wow animate__fadeInRightBig">
                                <h3 data-wow-delay="0.4s" class="wow animate__fadeInRightBig">Finanzas Personales</h3>
                            </div>
                        </div>
                        <div data-wow-delay="0.2s" class="wow animate__fadeIn col-12 col-lg-6 imgItemService order-0 order-lg-1">
                            <img class="img-fluid" src="assets/imgArticulos/art1.jpg" alt="">
                        </div>
                        <div class="col-12 col-lg-6 contService order-1 order-lg-0">
                            <div class="w-100">
                                <div [attr.data-wow-delay]="'0.'+ i + 's'" *ngFor="let item of personalFinance; index as i" class="wow animate__fadeIn row w-100 itemContenidoHE">
                                    <div [ngClass]="{'opacityHrDiv': i % 2 == 0}" [innerHTML]="hrLeft" class="col-4 col-md-2  alingHrLeft order-3 order-lg-3"></div>
                                    <div class="col-12 col-md-6  p-0 order-0 order-lg-2 contService">
                                        <div class="txtItem w-100">
                                            <p class="textFontAnthon">{{item.title}}</p>
                                            <p class="textFontDidact">{{item.subTitle}}</p>
                                        </div>
                                    </div>
                                    <div [ngClass]="{'opacityHrDiv': ! (i % 2 == 0)}" [innerHTML]="hrRight" class="col-4 col-md-2  alingHrRight order-lg-1"></div>
                                    <div class="col-4 col-md-2 p-0 contService order-lg-0">
                                        <div class="wow animate__bounceIn animate__slow seeMore w-100">
                                            <button (click)="openModal(item.typeModal, item.contentModal)">Leer más</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- fin seccion del segundo bloque de ventanas modales de servicios  -->
                <!-- inicio de la tercera seccion -->
                <!-- <div class="w-100 ">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="titleItem titleAling">
                                <h3 data-wow-delay="0.4s" class="wow animate__fadeInLeftBig">Coferencias Y Eventos</h3>
                                <hr data-wow-delay="0.2s" class="wow animate__fadeInLeftBig">
                                <h3 data-wow-delay="0.0s" class="wow animate__fadeInLeftBig">para Empresas</h3>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 imgItemService">
                            <img class="img-fluid" src="assets/imgArticulos/art1.jpg" alt="">
                        </div>
                        <div class="col-12 col-lg-6 contService">
                            <div class="w-100">
                                <div [attr.data-wow-delay]="'0.'+ i + 's'" *ngFor="let item of conferenceEvents; index as i" class="wow animate__fadeIn row w-100 itemContenidoHE">
                                    <div [ngClass]="{'opacityHrDiv': i % 2 == 0}" [innerHTML]="hrLeft" class="col-4 col-md-2  alingHrLeft order-3 order-lg-0"></div>
                                    <div class="col-12 col-md-6  p-0 order-0 order-lg-1 contService">
                                        <div class="txtItem w-100">
                                            <p class="textFontAnthon">{{item.title}}</p>
                                            <p class="textFontDidact">{{item.subTitle}}</p>
                                        </div>
                                    </div>
                                    <div [ngClass]="{'opacityHrDiv': ! (i % 2 == 0)}" [innerHTML]="hrRight" class="col-4 col-md-2  alingHrRight order-lg-2"></div>
                                    <div class="col-4 col-md-2 p-0 contService order-lg-3">
                                        <div class="wow animate__bounceIn animate__slow seeMore w-100">
                                            <button (click)="openModal(item.typeModal, item.contentModal)">Leer más</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- fin de los objetos del delanteros -->
        </div>
    </div>
    <!-- fin de los objetos de servicios -->
</section>