<div id="hablemos">
    <section id="main-container" class="bg-1 txtColor1">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)" id="contact-form" role="form">
                        <div class="row">
                            <div data-wow-delay="0.5s" class="wow animate__fadeInLeft col-md-4">
                                <div class="form-group">
                                    <label>Nombre</label>
                                    <input class="form-control" name="name" [(ngModel)]="FormContact.name" id="name"
                                        placeholder="" type="text" required>
                                </div>
                            </div>
                            <div data-wow-delay="0.3s" class="wow animate__fadeInLeft col-md-4">
                                <div class="form-group">
                                    <label>Correo</label>
                                    <input class="form-control" name="email" [(ngModel)]="FormContact.mail" id="email"
                                        placeholder="" type="email" required>
                                </div>
                            </div>
                            <div data-wow-delay="0.1s" class="wow animate__fadeInLeft col-md-4">
                                <div class="form-group">
                                    <label>Asunto del Mensaje</label>
                                    <input class="form-control" name="subject" [(ngModel)]="FormContact.subject"
                                        id="subject" placeholder="" required>
                                </div>
                            </div>
                        </div>
                        <div data-wow-delay="0.15s" class="wow animate__fadeInLeft form-group">
                            <label>Mensaje</label>
                            <textarea class="form-control" name="message" [(ngModel)]="FormContact.messageFront"
                                id="message" placeholder="" rows="10" required></textarea>
                        </div>
                        <div data-wow-delay="0.2s" class="wow animate__fadeIn text-Left"><br>
                            <button class="btn btn-danger solid blank" type="submit">Enviar</button>
                        </div>
                    </form>
                </div>
                <div class="col-md-5">
                    <div class="contact-info">
                        <div class="">
                            <h3 data-wow-delay="0.2s" class="wow animate__fadeInDown">Contactenos</h3>

                        </div>
                        <br>
                        <div class="">
                            <p data-wow-delay="0.6s" class="wow animate__fadeInDown"><i class="fa fa-phone info"></i>601
                                3029467</p>
                            <p data-wow-delay="0.8s" class="wow animate__fadeInDown"><i
                                    class="fa fa-envelope-o info"></i>educacion@lccempresarial.com</p>
                            <p data-wow-delay="1s" class="wow animate__fadeInDown"><i
                                    class="fa fa-globe info"></i>lccempresarial.com</p>
                            <!-- </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ container end -->
    </section>
</div>
<!--/ Main container end -->

<!-- Footer start -->
<footer id="footer" class="footer bg-1">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-12 footer-widget">
                <h3 class="wow animate__fadeInLeft widget-title">Publicaciones Recientes</h3>
                <div *ngFor="let artItem of dataPost; index as i" class="contentPost">
                    <div class="wow animate__fadeInLeft latest-post-items media">
                        <div class="latest-post-content media-body">
                            <h4 class="linkArtDespl">
                                <a (click)="changeFragment('Articulos','art'+(i+1))" href="#" onclick="return false;">
                                    <h6 class="textArticle  textFontDidact linkArtStyle">{{artItem.artTitle}}</h6>
                                </a>
                            </h4>
                            <p class="post-meta">
                                <span class="author">Posteado por {{artItem.postedBy}}</span>
                                <span class="post-meta-cat textFontDidact float-right mr-2">{{artItem.datePost}}</span>
                            </p>
                        </div>
                    </div>
                </div>


            </div>
            <!--/ End Recent Posts-->


            <div class="col-md-4 col-sm-12 footer-widget">
                <h3 class="widget-title text-center wow animate__bounceIn animate__slow">Socios</h3>

                <div class="img-gallery">
                    <div class="img-container text-center">
                        <a class="thumb-holder wow animate__bounceInUp" href="">
                            <img class="sam ml-3" src="assets/logosSocios/logoSam.png" alt="">
                        </a>

                    </div>
                </div>
            </div>
            <!--/ end flickr -->

            <div class="col-md-3 col-sm-12 footer-widget footer-about-us">
                <h3 class="widget-title wow animate__fadeInRight">Sobre Nosotros</h3>
                <p class="text-justify wow animate__fadeInRight">Nacimos con la transformación digital para apoyar el
                    crecimiento empresarial de Latinoamérica,
                    hacemos posible los sueños de crecimiento de su compañía por medio del conocimiento y la asesoría
                    empresarial.</p>
                <div class="row">
                    <div data-wow-delay="0.1s" class="wow animate__fadeInRight col-md-12">
                        <h4>Email:</h4>
                        <p>educacion@lccempresarial.com</p>
                    </div>
                    <div data-wow-delay="0.3s" class="wow animate__fadeInRight col-md-12">
                        <h4>Telefono No.</h4>
                        <p>6013029467</p>
                    </div>
                </div>
            </div>
            <!--/ end about us -->

        </div>
        <!-- Row end -->
    </div>
    <!-- Container end -->
</footer>
<!-- Footer end -->


<!-- CopyLeft start -->
<section id="copyLeft" class="copyLeft angle bg-1">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <ul class="footer-social unstyled">
                    <li>
                        <a title="Facebook" href="#">
                            <span class="icon-pentagon wow wow animate__backInUp"><i class="fa fa-facebook"></i></span>
                        </a>
                        <a title="Instagram" href="#">
                            <span class="icon-pentagon wow wow animate__backInUp"><i class="fa fa-instagram"></i></span>
                        </a>
                        <a title="YouTube" href="#">
                            <span class="icon-pentagon wow wow animate__backInUp"><i
                                    class="fa fa-youtube-play"></i></span>
                        </a>

                    </li>
                </ul>
            </div>
        </div>
        <!--/ Row end -->
        <div class="row">
            <div class="col-md-12 text-center">
                <div data-wow-delay="0.5s" class="copyLeft-info txtColor1 wow animate__backInUp">
                    &copy; CopyRight <i class="fa fa-copyright" aria-hidden="true"></i> 2020 SAM. <br>
                    <a class="copyLeft-info txtColor1" routerLink="/terminos">
                        Terminos y Condiciones
                    </a>
                </div>
            </div>
        </div>

    </div>
    <!--/ Container end -->
</section>
<!--/ CopyLeft end -->