<div class="terminos">
  <section>
    <h1>POLITICA DE PRIVACIDAD</h1>
    <h2>Life Control Channel S.A.S.</h2>

    <p>ACTUALIZADO: 1 DE JUNIO DE 2020</p>
    <p>Agradecemos que estés en esta página, eso significa que tus datos te importan, y quieres conocer el destino de
      estos
      y quién los recoge.</p>

    <p>Política de privacidad de: <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> propiedad de Life
      Control Channel S.A.S.</p>

    <p>Esta Página Web recoge algunos Datos Personales de sus Usuarios.</p>

    <h3>Datos de Contacto:</h3>
    <p>Titular y Responsable del tratamiento de los Datos</p>
    <p>Life Control Channel S.A.S / Bogotá, Colombia</p>
    <p>Correo electrónico de contacto del Titular: <a
        href="mailto:lifecontrolchannel@gmail.com">lifecontrolchannel@gmail.com</a>, <a
        href="mailto:educacion@lccempresarial.com">educacion@lccempresarial.com</a></p>

    <h3>Entre las clases de Datos Personales que recoge esta Página Web, ya sea directamente o a través de terceros, se
      encuentran:</h3>
    <ul>
      <li>Cookies</li>
      <li>Datos de uso</li>
      <li>Nombre</li>
      <li>Apellido(s)</li>
      <li>Número de teléfono</li>
      <li>Dirección de correo electrónico</li>
      <li>Sexo</li>
      <li>Fecha de nacimiento</li>
      <li>Dirección</li>
      <li>País</li>
      <li>Ciudad</li>
      <li>ID de Usuario</li>
      <li>Identificadores únicos de dispositivo para publicidad</li>
      <li>Información financiera con el fin de realizar la correspondiente facturación</li>
      <li>Acerca de mí</li>
      <li>Datos comunicados durante el uso del servicio</li>
      <li>Posición geográfica y distintas clases de Datos</li>
    </ul>

    <p>La información completa referente a cada categoría de Datos Personales que se recogen se proporciona en las
      secciones
      de la presente política de privacidad dedicadas a tal fin o mediante textos explicativos específicos que se
      muestran
      antes de la recogida de dichos Datos.</p>

    <p>Los Datos Personales podrán ser proporcionados libremente por el Usuario o, en caso de los Datos de Uso, serán
      recogidos automáticamente cuando se utilice esta Página Web.</p>

    <p>Todos los Datos solicitados por esta Página Web son obligatorios y la negativa a proporcionarlos podrá
      imposibilitar
      que esta Página Web pueda proceder a la prestación del Servicio. En los casos en los que esta Página Web indique
      específicamente que ciertos Datos no son obligatorios, los Usuarios serán libres de no comunicar tales Datos sin
      que
      esto tenga consecuencia alguna sobre la disponibilidad o el funcionamiento del Servicio. Los Usuarios que tengan
      dudas
      sobre qué Datos son obligatorios pueden contactar con el Titular.</p>

    <p>El uso de Cookies - o de otras herramientas de seguimiento - por parte de esta Página Web o por los titulares de
      servicios de terceros utilizados por esta Página Web tiene como finalidad la prestación del Servicio solicitado
      por el
      Usuario, además de cualesquiera otras finalidades que se describan en el presente documento y en la Política de
      Cookies, en caso de estar disponible.</p>

    <p>El Usuario asume la responsabilidad respecto de los Datos Personales de terceros que se obtengan, publiquen o
      compartan a través de esta Página Web y declara por la presente que tiene el consentimiento de dichos terceros
      para
      proporcionar dichos Datos al Titular.</p>

    <p>La presente Política de Privacidad será válida únicamente para los datos de carácter personal obtenidos en el
      Sitio
      Web, no siendo aplicable para aquella información recabada por terceros en otras páginas web, incluso si éstas se
      encuentran enlazadas por el Sitio Web. Con ello manifiesto mi compromiso por mantener y garantizar las relaciones
      comerciales de forma seguridad mediante la protección de los datos personales y garantizando el derecho a la
      privacidad de cada uno de los usuarios de nuestro sitio web.</p>

    <h2>CLASES DE DATOS RECOGIDOS</h2>
    <p>Debes saber que sería cualquier información relativa a una persona que nos facilita cuando visita nuestro sitio
      web,
      en nuestro caso nombre e email, y si compra algún producto necesitando factura, solicitaremos Domicilio completo,
      nombre, apellidos y DNI o CIF.</p>

    <p>Adicionalmente, cuando visitas nuestro sitio web, determinada información se almacena automáticamente por motivos
      técnicos como la dirección IP asignada por su proveedor de acceso a Internet.</p>

    <h2>LA FINALIDAD</h2>
    <p>Tal y como se recoge en la normativa, se informa al USUARIO que, a través de los formularios de contacto, o
      suscripciones se recaban datos, los cuales se almacenan en un fichero, con la exclusiva finalidad de envío de
      comunicaciones electrónicas, tales como: boletines (newsletters), nuevas entradas (posts), ofertas comerciales,
      webinars gratuitos, así como otras comunicaciones que tanto <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> y Life Control Channel S.A.S. entiende
      interesantes
      para sus USUARIOS. Los campos marcados como de cumplimentación obligatoria, son imprescindibles para realizar la
      finalidad expresada. Así mismo podrá dar cumplimiento mediante los datos, a los requerimientos solicitados por los
      USUARIOS.</p>

    <p>En definitiva la FINALIDAD es la siguiente:</p>

    <ul>
      <li>La venta de formación libre e independiente sin clasificarla como formal sino meramente como entrenamiento sin
        constituir asesoría, consejería y servicios sobre temas financieros.</li>
      <li>Gestionar la lista de suscriptores y usuarios adscritos al curso y realizar el seguimiento de su proceso, en
        algunos casos se usará para envío de alertas de motivación al continuar activo en el proceso de formación.</li>
      <li>Suministro de contenidos en el blog, videos, artículos y demás complementos que el Titular vea necesario para
        complementar los contenidos.</li>
      <li>Dirigir su red de afiliados y comerciantes así como la gestión de pagos de los mismos.</li>
    </ul>

    <p>Únicamente el titular tendrá acceso a sus datos, y bajo ningún concepto, estos datos serán cedidos, compartidos,
      transferidos, ni vendidos a ningún tercero para fines comerciales o de cualquier otro tipo.</p>

    <p>La aceptación de la política de privacidad, mediante el procedimiento establecido de registro o vinculación o
      compra
      de material de entrenamiento, se entenderá a todos los efectos como la prestación de CONSENTIMIENTO EXPRESO E
      INEQUÍVOCO del USUARIO al tratamiento de los datos de carácter personal en los términos que se exponen en el
      presente
      documento, así como a la transferencia internacional de datos que se produce, exclusivamente debido a la ubicación
      física de las instalaciones de los proveedores de servicios y encargados del tratamiento de datos.</p>

    <p>En ningún caso se realizará un uso diferente que la finalidad para los que han sido recabados los datos ni muchos
      menos cederé a un tercero estos datos.</p>

    <h2>LO QUE SUCEDE CUANDO ERES MENORES DE EDAD</h2>
    <p>En el supuesto de ser mayor de trece años, podrás registrarte en <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> sin necesidad del consentimiento previo de tus
      padres o tutores.</p>

    <p>¿Qué ocurre en el caso de que seas menor de 14 años?</p>

    <p>En este supuesto, será condición obligatoria el consentimiento de tus padres o tutores para que podamos tratar
      sus
      datos personales</p>

    <p>Advertencia: Si tienes menos de catorce años y no has obtenido el consentimiento de tus padres, no puedes
      registrarte
      en la web por lo que procederemos a denegar su solicitud en caso de tener constancia de ello.</p>

    <h2>LEGITIMACIÓN</h2>
    <p>Gracias al consentimiento, podemos tratar tus datos siendo requisito obligatorio para poder suscribirte a la
      página
      web. Esto aplica hasta que de manera libre y personal se manifieste lo contrario informando al Titular de esta
      decisión.</p>

    <h2>CATEGORÍA DE LOS DATOS</h2>
    <p>Los datos recabados en ningún momento son especialmente protegidos, sino que se encuentran categorizados como
      datos
      identificativos.</p>

    <h2>TIEMPO DE CONSERVACIÓN DE LOS DATOS</h2>
    <p>Conservaremos tus datos durante el tiempo legalmente establecido o hasta que solicites eliminarlos.</p>

    <h2>EXACTITUD Y VERACIDAD DE LOS DATOS</h2>
    <p>Obviamente eres el único responsable de la veracidad y exactitud de los datos que me remitas eximiéndonos de
      cualquier tipo de responsabilidad al respecto. Como usuario, debes garantizar la exactitud y autenticidad de los
      datos
      personales facilitados debiendo aportar la información completa y correcta en los distintos formularios de
      captación
      de datos.</p>

    <h2>CUMPLIMIENTO DE LA NORMATIVA DE APLICACIÓN.</h2>
    <p><a href="http://www.lccempresarial.com">www.lccempresarial.com</a> y Life Control Channel S.A.S. hasta la fecha
      cumple con las directrices de la normativa vigente y de aplicación en cada momento, velando por garantizar un
      correcto
      uso y tratamiento de los datos personales del usuario.</p>

    <p>Actualmente, nos regiremos por la normativa en aplicación, siendo el Reglamento General sobre Protección de Datos
      (RGPD) de la Unión Europea.</p>

    <p>En cumplimiento de lo establecido en la normativa, le informamos que los datos suministrados, así como aquellos
      datos
      derivados de su navegación, podrán ser almacenados en los ficheros de Life Control Channel S.A.S y el titular de
      <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> y tratados para la finalidad de atender su
      solicitud
      y el mantenimiento de la relación que se establezca en los formularios que suscriba.
    </p>

    <p>Adicionalmente, el USUARIO consiente del tratamiento de sus datos con la finalidad de informarles, por cualquier
      medio, incluido el correo electrónico, de productos y servicios de <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> o su matriz Life Control Channel S.A.S.</p>

    <p>En caso de no autorizar el tratamiento de sus datos con la finalidad señalada anteriormente, el USUARIO podrá
      ejercer
      su derecho de oposición al tratamiento de sus datos en los términos y condiciones previstos más adelante en el
      apartado “Ejercicio de Derechos”</p>

    <h2>MEDIDAS DE SEGURIDAD.</h2>
    <p><a href="http://www.lccempresarial.com">www.lccempresarial.com</a> o su matriz Life Control Channel S.A.S. le
      informa
      que tiene implantadas las medidas de seguridad de índole técnica y organizativas necesarias para garantizar la
      seguridad de sus datos de carácter personal y evitar su alteración, pérdida y tratamiento y/o acceso no
      autorizado,
      habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están
      expuestos,
      ya provengan de la acción humana o del medio físico o natural. Todo ello de conformidad con lo previsto en el
      RGPD.
    </p>

    <h2>EJERCICIO DE DERECHOS</h2>
    <p>Aquellas personas físicas que hayan facilitado sus datos a través de <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a>, podrán dirigirse al titular de la misma con el
      fin
      de poder ejercitar gratuitamente sus derechos de acceso a sus datos, rectificación o supresión, limitación y
      oposición
      respecto de los datos incorporados en sus ficheros. El método más rápido y sencillo sería enviando comunicado
      escrito
      al Titular a los correos electrónicos mencionados en esta política lo cual dentro del plazo de 72 horas se
      responderá
      a la solicitud. Cualquier información que necesitemos almacenar, en virtud de una obligación legal o contractual,
      será
      bloqueada y sólo utilizada para dichos fines en lugar de ser borrada. El interesado podrá ejercitar sus derechos
      mediante comunicación por escrito dirigida al titular de esta política de privacidad con el asunto “Protección de
      datos”, especificando sus datos, acreditando su identidad y los motivos de su solicitud al siguiente correo
      electrónico: lifecontrolchannel@gmail.com y con copia educacion@lccempresarial.com</p>

    <h2>LINKS O ENLACES EXTERNOS</h2>
    <p>Como un servicio a nuestros visitantes, nuestro sitio web puede incluir hipervínculos a otros sitios que no son
      operados o controlados por el Sitio Web. Por <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> no
      garantiza, ni se hace responsable de la licitud, fiabilidad, utilidad, veracidad y actualidad de los contenidos de
      tales sitios web o de sus prácticas de privacidad. Por favor, antes de proporcionar su información personal en
      estos
      sitios web ajenos a <a href="http://www.lccempresarial.com">www.lccempresrial.com.com</a>, tenga en cuenta que sus
      prácticas de privacidad pueden diferir de las nuestras o carecer de políticas de privacidad de datos.</p>

    <p>El único objeto de los enlaces es proporcionar al Usuario la posibilidad de acceder a dichos enlaces y conocer
      nuestro trabajo, aunque <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> y Life Control Channel
      S.A.S. no ofrece ni comercializa por sí ni por medio de terceros la información, contenidos y servicios
      disponibles en
      los sitios enlazados, ni aprueba, supervisa o controla en modo alguno los contenidos y servicios y cualquier
      material
      de cualquier naturaleza existente en los mismos. <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> y
      Life Control Channel S.A.S. no se responsabiliza en ningún caso de los resultados que puedan derivarse al Usuario
      por
      acceso a dichos enlaces.</p>

    <h2>MODIFICACIÓN DE LA POLÍTICA DE PRIVACIDAD</h2>
    <p><a href="http://www.lccempresarial.com">www.lccempresarial.com</a> y Life Control Channel S.A.S. se reserva el
      derecho a modificar su Política de Privacidad, de acuerdo a su propio criterio, motivado por un cambio
      legislativo,
      jurisprudencial o doctrinal. Cualquier modificación de la Política de Privacidad será publicada al menos diez días
      antes de su efectiva aplicación. El uso de <a href="http://www.lccempresarial.com">www.lccempresarial.com</a>
      después
      de dichos cambios, implicará la aceptación de los mismos.</p>

    <h2>RESPONSABLE DEL FICHERO, Y ENCARGADOS DEL TRATAMIENTO.</h2>
    <p>El responsable del fichero de datos es Life Control Channel S.A.S.</p>
    <p>Como encargados de tratamiento ajenos al citado responsable:</p>
    <ul>
      <li>Life Control Channel S.A.S. <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> ha contratado
        los
        servicios de SAM S.A.S. quien actúa como proveedor tecnológico. Así mismo, Life Control Channel S.A.S. ha
        contratado
        los servicios de hosting, suscripción y envío de newsletters a la compañía myasp.net pudiéndose consultar su
        política de privacidad en <a href="https://www.myasp.net/privacy">https://www.myasp.net/privacy</a></li>
    </ul>

    <h2>SERVICIOS OFRECIDOS POR TERCEROS EN ESTA WEB.</h2>
    <p>Para prestar servicios estrictamente necesarios para el desarrollo de mi actividad, <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> se utiliza los siguientes prestadores bajo sus
      correspondientes condiciones de privacidad.</p>
    <ul>
      <li>Hosting: myasp.net</li>
      <li>Plataforma web: myasp.net</li>
      <li>Servicios de Mensajería y envío de boletines: myasp.net</li>
      <li>Plataforma de pago: PayU</li>
      <li>Medios de pago: todos los ofrecidos por PayU</li>
      <li>Privacidad de fuentes de rastreo utilizadas en este sitio: Google (Analytics) y Facebook (Píxel)</li>
    </ul>

    <p>En <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> también se estudian las preferencias de sus
      usuarios, sus características demográficas, sus patrones de tráfico, y otra información en conjunto para
      comprender
      mejor quiénes constituyen nuestra audiencia y qué es lo que necesita. El rastreo de las preferencias de nuestros
      usuarios también nos ayuda a mostrarle los avisos publicitarios más relevantes.</p>

    <p>El usuario y, en general, cualquier persona física o jurídica, podrá establecer un hiperenlace o dispositivo
      técnico
      de enlace (por ejemplo, links o botones) desde su sitio web a <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> (el “Hiperenlace“). El establecimiento del
      Hiperenlace no implica en ningún caso la existencia de relaciones entre <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> y el propietario del sitio o de la página web en
      la
      que se establezca el Hiperenlace, ni la aceptación o aprobación por parte de <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> de sus contenidos o servicios. En todo caso, <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> se reserva el derecho de prohibir o inutilizar
      en
      cualquier momento cualquier Hiperenlace al Sitio Web.</p>

    <h2>DESEAS REVOCAR TU CONSENTIMIENTO</h2>
    <p>De conformidad con lo dispuesto en la Ley puedes oponerte al uso de su información para fines publicitarios,
      investigaciones de mercado o desarrollo de encuestas de satisfacción en cualquier momento, así como revocar tu
      consentimiento en cualquier momento (sin efecto retroactivo).</p>

    <p>Para ello, deberás enviar un correo electrónico a la dirección lifecontrolchannel@gmail.com con copia a
      educacion@lccempresarial.com. Si has recibido publicidad por correo electrónico, también podrás oponerte desde
      dicho
      correo electrónico, pinchando en el enlace incluido en el mismo siguiendo las instrucciones que te sean
      facilitadas.
    </p>

    <p>Por favor, ten en cuenta que nuestros sistemas pueden requerir un lapso de tiempo que en ningún caso superará 72
      horas para que tu oposición o revocación se hagan efectivas, entendiéndose que durante dicho período de tiempo aún
      puedes seguir recibiendo mensajes.</p>

    <p>En relación con la gestión de tus datos asociados a los perfiles sociales de <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> Life Control Channel S.A.S., el ejercicio del
      derecho de acceso, dependerá de la funcionalidad de la red social y las posibilidades de acceso a la información
      de
      los perfiles de los usuarios. Con relación a los derechos de acceso y rectificación, le recomendamos que solo
      podrá
      satisfacerse en relación a aquella información que se encuentre bajo el control de <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a> Life Control Channel S.A.S.</p>

    <p>Además podrá dejar de interactuar, seguir o recibir información de los perfiles sociales de <a
        href="http://www.lccempresarial.com">www.lccempresarial.com</a>, eliminar los contenidos que dejen de
      interesarte o
      restringir con quien comparte sus conexiones, mediante los mecanismos estipulados en las diferentes redes
      sociales.
    </p>

    <p>El usuario podrá acceder a las políticas de privacidad de cada Red Social, así como configurar su perfil para
      garantizar su privacidad. <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> Life Control Channel
      S.A.S. anima a los usuarios a familiarizarse con las condiciones de uso de las distintas redes sociales antes de
      comenzar a usarlas.</p>

    <h2>¿QUÉ OCURRE EN CASO DE CAMBIOS EN LA POLÍTICA DE PRIVACIDAD?</h2>
    <p><a href="http://www.lccempresarial.com">www.lccempresarial.com</a> y Life Control Channel S.A.S. se reserva el
      derecho de modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales, así como a
      prácticas de la industria. En dichos supuestos, el Prestador anunciará en esta página los cambios introducidos con
      razonable antelación a su puesta en práctica.</p>

    <p>Correos comerciales</p>
    <p>De acuerdo con la LSSICE, <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> no realiza prácticas
      de
      SPAM, por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o
      autorizados por el Usuario. En consecuencia, en cada uno de los formularios habidos en la Página Web, el Usuario
      tiene
      la posibilidad de dar su consentimiento expreso para recibir el boletín, con independencia de la información
      comercial
      puntualmente solicitada.</p>

    <p>Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio
      electrónico, <a href="http://www.lccempresarial.com">www.lccempresarial.com</a> se compromete a no enviar
      comunicaciones de carácter
      comercial sin identificarlas debidamente.</p>

    <p>Documento revisado el 1 de junio de 2020.</p>
  </section>
</div>