// tslint:disable: max-line-length
export const Articulo1 = '<div class="articuloTxt">' +
'                                <p>Dentro de la economía digital en la cual el mundo ha basado en el hoy por hoy el tráfico de sus contenidos, con el fin de crear visualización de marca que le permita tanto a las empresas como a las personas mostrar sus' +
'                                    productos o servicios creando constantemente en las redes sociales publicaciones de piezas gráficas de todo tipo; podemos analizar cuidadosamente el aporte que este comportamiento hace al posicionamiento de marca.</p>' +
'                                <h4>Antecedentes del uso de la comunicación gráfica en el mundo digital</h4>' +
'                                <p>Para los inicios del año 2.000 el mundo ya tenía consolidada la red de comunicación telefónica móvil por un lado y por otro veíamos como cada día aumentaba el tráfico de mensajes vía correos electrónicos como medio de comunicación' +
'                                    especialmente empresarial. Esto por separado funcionaba en su entonces de manera correcta pero poco atractiva para los jóvenes ya que la comunicación de texto se tornaba opaca, rígida y aburrida, pero de igual manera' +
'                                    necesaria para el desarrollo de la economía y la optimización de tiempos de mensajería corporativa.</p>' +
'                                <p>Como antecedentes de comunicación podemos observar que los primeros contenidos gráficos, antes de la aparición de las redes sociales, se consolidaron en las estrategias de email-marketing, con envíos masivos poco segmentados' +
'                                    y muy escuálidos en el contendido que se deseaba compartir. A pesar de todas las limitantes, muchas compañías crecieron con esta estrategia y se posicionaron con descuentos, promociones, juegos de azar y hasta ventas' +
'                                    directas.' +
'                                </p>' +
'                                <p>Con la aparición de las redes sociales, iniciando con Facebook, la novedad de esta impulsó al crecimiento de contenidos, esto apoyado con el desarrollo de teléfonos con mejores capacidades en la toma de fotografía, pero' +
'                                    aún no la deseada para los primeros años del nuevo milenio.</p>' +
'                                <p>El cambio que generó Facebook con la intención de encontrar nuevos recursos para su expansión vendiendo publicidad para empresas, productos y servicios, los cuales aparecerían en el denominado “muro” de todos los usuarios,' +
'                                    cambió la forma de ver la publicidad en el mundo, Facebook transformó la manera como se comunicarían las empresas en los siguientes años; pero continuando con los cambios en la transformación digital, el nuevo reto' +
'                                    consistía en competir en una red que no estaba diseñada para publicidad sino para relaciones entre amigos.</p>' +
'                                <p>Como todo lo que se crea en el mundo genera derivaciones competitivas o nuevos esquemas dentro del mercado; al pasar de los años nacen nuevas redes a lo largo y ancho del mundo, muchas murieron en su intento, pero se pueden' +
'                                    rescatar esencialmente dos: YouTube e Instagram. La Gigante compañía Google propietaria de YouTube se dedicó al contenido netamente audiovisual, esto también generó un cambio en el comportamiento de la sociedad y se' +
'                                    derivaron personajes dedicados a la creación de contenido, algunos científicos, otros empresariales, otros musicales los cuales tienen una participación significativa en dicha red, y otros de contenidos variados, creados' +
'                                    por personas especialmente jóvenes en búsqueda de reconocimiento y fama, estos últimos se autodenominaron “Youtubers”.</p>' +
'                                <p>Dicho lo anterior, sin saberlo los “Youtubers” impulsaron las bases de lo que podría ser la revolución del contenido digital, las personas ahora deseaban tener mayor información sobre lo que sucede en la vida de quien publica' +
'                                    en las redes sociales, lo que puso en jaque a la tradicional manera de comercializar las marcas, los productos y servicios. Ahora gracias a los “Youtubers” los contenidos de las marcas empresariales comenzaron a perder' +
'                                    relevancia, ya que estas solo se dedicaban a subir comerciales con el ánimo de vender un producto; pero por el efecto anteriormente descrito, se potencializaron conceptos en el mercado como lo es “la marca personal”' +
'                                    la cual combina conceptos empresariales, con conceptos personales, es decir, las marcas personales son empresas potenciales donde el proponente comparte contenido tanto gráfico como de video en las redes sociales con' +
'                                    el fin de crear un embudo para llevar ya no a sus “clientes”, sino a su comunidad de seguidores a adquirir sus servicios o productos.</p>' +
'                                <p>Por tal motivo no se sabe dónde inicia la palabra “influencer” pero su conceptualización está basada en la capacidad de una persona en hacer que una comunidad de seguidores adquiera o compren servicios o productos ya sea' +
'                                    de una empresa o una marca personal, esto lo logra hacer por medio de una “relación digital” casi de amistad con sus seguidores creando lazos de confianza que aumentan la credibilidad de su comunidad digital.</p>' +
'                                <h4>El empirismo en la creación de contenido gráfico para el posicionamiento de marca empresarial</h4>' +
'                                <p>Uno de los inconvenientes que se ha presentado, es la poca capacidad profesional para la creación de este contenido, en la mayoría de las ocasiones tanto empresas como marcas personales deben desarrollar su propias imágenes' +
'                                    y piezas publicitarias sin contar con la asesoría profesional que involucre no solo la toma, edición y composición de la mismas, sino la capacidad de “Engagement” entendido como: “el término que se utiliza en marketing' +
'                                    online para denominar el grado de implicación emocional que tienen los seguidores en redes sociales con dicha empresa.” (Sánchez, 2020)</p>' +
'                                <p>Cuando se habla de empirismo se aplica este concepto para explicar la falta de técnicas aplicadas en toma, edición y composición gráfica de las piezas puestas en las diferentes redes, o la falta de profesionalismo en la' +
'                                    edición de videos, no solo en las marcas personales también en las marcas empresariales; esto ha llevado a muchas marcas a presentar errores al momento de manejar sus redes sociales generando pérdidas en los recursos' +
'                                    de tiempo y monetarios implicados en la estrategia, por tal motivo estos contenidos no generan un “Engagement” que amplifique el tráfico y se convierta en ventas reales.</p>' +
'                                <p>Otro de los inconvenientes relevantes encontrados en este estudio, es la falta de interés de muchas empresas en invertir adecuadamente recursos para crear estrategias digitales asertivas y profesionales, la mayoría de estas' +
'                                    es por la falta de conocimiento y actualización de los encargados de esta labor denominados “Social media manager”, “Community manager” o simplemente “Administradores de Contenidos Digitales”.</p>' +
'                                <p>En la actualidad estos cargos han sido ocupados por profesionales de la publicidad, comunicadores sociales, psicólogos, mercadólogos y administradores de empresas que carecen del conocimiento y actualización del mismo para' +
'                                    comprender el comportamiento de la sociedad en las redes sociales, es decir, las estrategias planteadas por estos cargos en la mayoría no están basadas en estudios actuales sino en el conocimiento de la publicidad de' +
'                                    la vieja escuela.</p>' +
'                                <p>El cambio generacional también ha afectado las decisiones corporativas, las medias de edad han generado franjas que separa los pensamientos estratégicos que se deben incorporar, por esta razón tenemos dos generaciones y' +
'                                    hasta tres compitiendo por quien tiene la razón en la decisiones del marketing digital, inicialmente tenemos directivos corporativos que “su media de edad sobrepasa los 45 años de edad denominados Generación X o Generación' +
'                                    análoga” a los cuales les ha impactado las nuevas formas de ver y hacer contenido publicitario disruptivo en la era digital. Por otro lado, se encuentran “los Millennials con media de edad de 35 años actualmente”, los' +
'                                    cuales vivieron la transformación de la tecnología análoga a la tecnología digital; y finalmente tenemos a “la Generación Z con media de edad de 22 años”) y que ya se encuentran muchos de ellos incorporados laboralmente.</p>' +
'                                <p>Estas diferencias de generaciones en algunos casos deterioran las campañas publicitarias gráficas y audiovisuales y sus objetivos, especialmente en las decisiones de inversión como se mencionaba anteriormente. Esto impulsa' +
'                                    a las empresas a crear contenido que no genera ningún impacto en los clientes potenciales, es más, ni siquiera dichos contenidos están en la capacidad de crear una comunidad orgánica interesada en los productos o servicios.</p>' +
'                                <h4>El flujo de proceso en la creación de contenido digital con fines de visualización de marca</h4>' +
'                                <p>Finalmente se pretende sistematizar un flujo deseable para la aplicación del proceso de creación de contenido digital. Esta propuesta puede ser aplicada tanto en la marca personal como en la marca empresarial, con el fin' +
'                                    de dar una solución inicialmente esquemática para mejorar el proceder técnico en la creación de contenidos. La propuesta se basa en la observación de requerimientos actuales para cubrir las necesidades de creación de' +
'                                    contenidos digitales de alta calidad.</p>' +
'                                <p>La primera fase propuesta, al contrario de lo que se referencia por costumbre en el mercado que se basa en la ideación de la publicidad, lo que se propone como inicio es la creación del marco objetivo del alcance y la segmentación' +
'                                    del mercado del producto o servicio, con esta información podemos detallar cuál de las plataformas digitales o redes sociales ofrecen dicho alcance, puesto que la mercadología es especialista en esta área y no en todas' +
'                                    las plataformas se logra obtener la misma reacción para crear impacto, es decir no es lo mismo crear una piza gráfica basada en una fotografía para Instagram que para Facebook, los lenguajes de comunicación pueden variar' +
'                                    al tratarse de una red de fotografía y una red de socialización basada en la amistad y la creación de comunidades activas, sin entrar en detalle las posibilidades de tamaños gráficos, tiempos de duración de videos,' +
'                                    ubicaciones y restricciones que ofrece cada una de las redes más visitadas.</p>' +
'                                <p>En la segunda fase, es muy importante la creación de la composición gráfica o audiovisual ya sea del mensaje o producto que se está postulando para visualización, en esta composición se deben tener en cuenta los colores,' +
'                                    la presentación, e inclusive el outfit del personaje o los personajes, aquí la relación con un diseñador de imagen o de moda puede ser una elección ganadora en la relación, color, composición e impacto.</p>' +
'                                <p>En la tercera fase, la creación de la producción es muy importante, si es un video o si es una fotografía, por esta razón en este flujo de proceso se sugiere involucrar los conocimientos técnicos de un fotógrafo o camarógrafo' +
'                                    que le brinde la vida al mensaje y logre capturar la idea principal para llevarlo a una correcta edición de filtros o animaciones etc. En esta fase también es importante, en el caso de contenido audiovisual la locución,' +
'                                    una voz impactante ha de capturar mayormente la atención del prospecto y logrará una mejor recordación, en el caso de no tener este recurso de voz, se integrará un locutor que de brillo al contenido.</p>' +
'                                <p>Para la cuarta fase, es muy aconsejable en el caso que no se cuente con el conocimiento, involucrar la tecnología, ya que esta es pieza fundamental de la era digital y por ende se obtendrán maneras de recopilar el funcionamiento' +
'                                    de la campaña según las piezas gráficas y audiovisuales. Esto trata de técnicas de análisis de datos, donde podemos reconocer el rendimiento en las diferentes redes ya sea el orgánico o el pago, con el fin de saber' +
'                                    y determinar las inversiones monetarias que se deben realizar tanto en la actual campaña como en las posteriores y adicionalmente conocer con datos estadísticos, los resultados para tomar decisiones en futuras piezas' +
'                                    y campañas, recopilando tanto éxitos como fracasos, para ello podemos contar con conocimientos en estadística analítica orientada a la publicidad, administradores de bases de datos, ingenieros de software y análisis' +
'                                    entre otros.</p>' +
'                            </div>' ;

export const Articulo2 = '<div class="articuloTxt">' +
'                            <h4>¿QUÉ ES BRANDING?</h4>' +
'                            <p>Iniciemos entendiendo que Branding es un término Anglosajón que usualmente se usa en la mercadotecnia para referirse al conjunto de activos que se involucran en una marca. Estos activos generalmente son intangibles y es allí' +
'                                donde la atención del creador de la marca debe poner toda su atención. El Branding es el conjunto de procedimientos para gestionar la marca, es la esencia del cómo se puede crear un reconocimiento por el mercado por medio' +
'                                de técnicas definidas en diferentes medios de propagación o visualización.</p>' +
'                            <p>Lo que no debemos confundir con Branding es la creación de un logo, este hace parte de la gestión de marca pero no es en definitiva lo que define si se cuenta realmente o no con una marca.</p>' +
'                            <h4>¿QUIÉN DEFINE SI TIENES UNA MARCA O NO?</h4>' +
'                            <p>Definitivamente no somos nosotros mismos los que finalmente le damos valor a nuestra marca, realmente son los demás los que tienen la última palabra; es decir, <strong>la marca es lo que los demás piensan cuando recuerdan tu nombre o el nombre de la empresa,</strong>                                por eso no todos los logos se pueden considerar una marca, por esta razón la definición va más allá de un nombre, realmente es lo que esto representa para el mercado, los valores, la filosofía de negocio, la calidad asociada,' +
'                                la experiencia de compra, el aporte a la solución de problemas o la generación de satisfacción personal o colectiva.</p>' +
'                            <h4>¿LA GESTIÓN DEL BRANDING?</h4>' +
'                            <p>Entendiendo el concepto, pasemos a definir algunos aspectos que deben ser tenidos en cuenta para lograr potencializar el valor de la marca por medio de técnicas definidas. La esencia del branding es la capacidad que tiene la' +
'                                marca en generar experiencias únicas o por lo menos diferenciadoras que impulsen a una relación estrecha con la marca, una relación de fidelización por una alta afinidad de pensamientos, satisfacción, ideología, soluciones' +
'                                etc.' +
'                            </p>' +
'                            <p>La parte fundamental de la gestión de la marca debe estar orientada a las emociones que esta genera en el individuo, las emociones son básicas para lograr dicha relación y más aún en la era digital donde la fidelización se' +
'                                realiza por medio del contenido generado en las diferentes plataformas.</p>' +
'                            <p>En la creación de una buena estrategia de branding se deben destacar algunos puntos:</p>' +
'                            <ul class="ulLine">' +
'                                <li>¿Hacia donde queremos llevar a la audiencia?</li>' +
'                                <li>¿Por qué deseamos llevarlos a ese punto?</li>' +
'                                <li>¿Con qué vamos a contribuir a los demás?</li>' +
'                                <li>¿Lo que expresamos refleja nuestro objeto de operación?</li>' +
'                                <li>¿Realmente entrego y cumplo lo que digo como negocio?</li>' +
'                            </ul>' +
'                            <h4>EL BRANDING COMO CONQUISTA DE SENTIMIENTOS</h4>' +
'                            <p>Continuando con los fundamentos del branding, debemos recordar que hoy en día es cada vez más difícil conquistar la mirada de un prospecto, por lo cual no podemos definir como cliente a quien realmente no compre nuestros productos' +
'                                o servicios. El branding es un proceso de conquista sentimental, que se basa en las emociones generadas, por esto no se define como una estrategia directa de venta. Muchos errores se encuentran en las estrategias donde' +
'                                se confunde una gestión de marca con publicidad de productos o servicios, el branding va más allá de esto y claro está que la consecuencia en el “amor” generado es la compra de productos por parte del cliente. Generar emociones,' +
'                                sentimientos debe ser el objetivo inicial para el posicionamiento de la marca, es por ello que todas las estrategias sobre la relación, la atención, el escuchar al cliente, el lograr una conexión, el brindar valor sin pensar' +
'                                en los gastos asociados es un buen camino para iniciar esta relación que se espera sea de largo plazo. </p>' +
'                            <h4>EL BRANDING LOS PRIMEROS PASOS</h4>' +
'                            <p>Vamos a iniciar con la gestión de la marca, los primeros pasos están en las manos de los fundadores que son quienes deben impartir el espíritu por el cual ha nacido la empresa o negocio.</p>' +
'                            <ol>' +
'                                <li>Define ampliamente en un tablero de trabajo los servicios y productos.</li>' +
'                                <li>Crea valores agregados para cada uno de ellos y para todos en conjunto.</li>' +
'                                <li>Crea una personalidad de la marca, que hable a los clientes.</li>' +
'                                <li>Define una filosofía de negocio la cual deben conocer todos los colaboradores.</li>' +
'                                <li>Redacta el mensaje concreto que deseas que se conozca, el aporte que da la marca a la sociedad.</li>' +
'                                <li>No olvides los valores de marca, ¿Qué te define como empresa?</li>' +
'                                <li>Humaniza la marca, no puedes ser frio solo pensando en vender.</li>' +
'                            </ol>' +
'                        </div>';

export const Articulo3 = '<div class="articuloTxt">' +
'                            <h4>¿MUEREN LOS TITULOS UNIVERSITARIOS?</h4>' +
'                            <p><strong>¡</strong>No necesariamente<strong>!</strong> los profesionales siempre se van a requerir en una sociedad especialmente en desarrollo, la evolución de la economía requiere de la investigación que es uno de los pilares' +
'                                fundamentales de la educación. <strong>¿Pero qué pasaría si podemos investigar de manera libre? </strong> Por siglos las universidades han sido el espacio donde se han logrado miles de descubrimientos, desarrollos y modelos' +
'                                para ser aplicados en las diferentes industrias.</p>' +
'                            <p>El problema radica en la velocidad. Nos estamos enfrentando a diferentes velocidades de evolución, por un lado tenemos un mercado que todos los días requiere de nuevos desarrollos, nuevas técnicas, todo esto impulsado por la' +
'                                revolución tecnológica; yo por otro lado tenemos la lentitud en los procesos formales de investigación de las universidades, la actualización del personal docente, la educación basada en lo que fue y no en lo que viene,' +
'                                esto dado por las regulaciones estatales y los académicos puros que analizan lo que ya pasó con teorías que en el hoy quizás ya no son válidas para el mercado y los negocios.</p>' +
'                            <p>Los títulos universitarios no mueren, lo que muere es la vigencia del conocimiento impartido en las aulas. Cuando analizamos los programas académicos universitarios, estos están diseñados para 5 años o más, cuando un estudiante' +
'                                ingresa a un programa académico seguramente cuando sale dicha impartición y transferencia de conocimiento ya no es vigente para el mercado y requiere de actualizaciones que seguramente desvirtúan gran parte de lo aprendido' +
'                                dada la evolución.</p>' +
'                            <h4>¿CÓMO DEBEMOS CONTRATAR EN LA ERA DIGITAL?</h4>' +
'                            <p>Sin echar de menos los títulos universitarios, en el hoy por hoy los requerimientos administrativos, financieros, tecnológicos de las empresas deben estar a la vanguardia de lo que sucede en los negocios. En el contexto empresarial,' +
'                                sin contar los servicios médicos que por ende deben contar con un título profesional para ejercer, todo trabajo que se requiera en una empresa debería ser confrontado a una prueba de conocimientos aplicados, es decir, crear' +
'                                certificaciones propias con la demanda de funciones que el cargo amerita.</p>' +
'                            <p>El proceso de contratación no debe iniciar con el famoso filtro de títulos universitarios, debería iniciar con la demostración de habilidades y competencias para ejercer y aportar al cargo. En la situación que un aspirante' +
'                                demuestre que puede ejercer las funciones con alta calidad debería ser opcional la presentación del título universitario. </p>' +
'                            <h4>EL AUTOAPRENDISAJE Y LA INVESTIGACIÓN</h4>' +
'                            <p>Finalmente debemos comprender que en un mundo donde encontramos contenidos digitales, donde internet nos presenta cientos de opciones para capacitarnos con alta calidad tanto técnica como en fundamentación, brindada por expertos' +
'                                que no solo teóricamente conocen los saberes sino que realmente han aplicado e implementado sus técnicas, teorías y conocimientos, es por ello que podemos tener a la mano miles de contenidos.</p>' +
'                            <p>El autoaprendizaje es una de las formas como los seres humanos están aprendiendo hoy en día, la investigación, la fundamentación teórica también hace parte de este nuevo movimiento. <strong>Quizás en un futuro la totalidad de la educación se realice en ambientes digitales y los profesores presenciales se conviertan en mentores que transmitan más su experiencia que el conocimiento.</strong></p>' +
'                        </div>';

export const Articulo4 = '<div class="articuloTxt">' +
'                            <h4>NO TODO EMPRENDIMIENTO ES UNA EMPRESA POTENCIAL</h4>' +
'                            <p>A pesar que vivimos en una época denominada la “era del emprendimiento” debemos tener mucha atención a los negocios que se están gestando por estos tiempos. La “euforia” de la creación de empresas ha determinado un comportamiento' +
'                                marcando una tendencia que impulsa cada día a cientos de personas a optar por buscar una fuente de ingresos, ya sea principal o secundaria a los ingresos laborales corrientes.</p>' +
'                            <p>No todo emprendimiento es una verdadera creación de empresa con un potencial de crecimiento, sostenimiento en el tiempo y mucho menos una generación de riqueza que pueda suplir las necesidades de un individuo o quizás una familia.</p>' +
'                            <p>La creación de empresas se ha incrementado a tasas gigantescas en los últimos años, esto impulsado por los altos niveles de desempleo que afrontan los países en desarrollo; la tecnología también ha generado oportunidades de' +
'                                comercialización y visualización de marca. No todo es color de rosa en cuanto a la actividad de emprender, constantemente se ven muchos vacíos en la educación de negocios de las personas que intentan crear, diseñar o comercializar' +
'                                un producto o servicio.</p>' +
'                            <p>Vemos cada día más ventas de productos artesanales, productos hechos a mano, productos de líneas que cubren desde vestuario hasta accesorios para mujeres y hombres; el problema no radica en la calidad de estos productos, radica' +
'                                principalmente en dos puntos especiales: la concentración de estos y la poca o nula infraestructura y procesos industrializados. Claro está que si hablamos de productos artesanales no deberíamos estar hablando de procesos' +
'                                de industrialización, pero para el crecimiento y la masificación de las ventas es importante poder suplir la demanda de crecimiento.</p>' +
'                            <h4>LOS CUENTOS CHINOS DE LOS MENTORES DE EMPRENDIMIENTO</h4>' +
'                            <p>Si un mentor no tiene la capacidad de dar luces a una idea de negocio y no tiene la capacidad de decir <strong>“eso no es un emprendimiento” </strong> hay que escapar de ese mentor. Vemos como se comercializan cursos, entrenamientos,' +
'                                conferencias, trucos mágicos que ofrecen crecer las ventas, casi toda clase de personas que realmente no han pasado por el doloroso proceso de emprender, que jamás han estado en el montaje de una empresa real y que su única' +
'                                empresa es vender asesorías para crear empresas.</p>' +
'                            <p>En un mundo donde muchos se sacrifican para crear sus negocios, también han llegado los falsos asesores, que han hecho que los verdaderos mentores sean tomados por ridículos. Lo preocupante en todo esto, es que las personas' +
'                                prefieren escuchar a quienes les venden humo en estos temas, a los que dicen hacer crecer los negocios mágicamente con una sola conferencia o una fórmula mágica o una feria de emprendimiento. </p>' +
'                            <p>No todo el que se denomine mentor o coach lo es, pero tampoco se requiere caer en la nueva moda de requerir una certificación de una escuela de mentores para poderlo ejercer; la mejor escuela para poder apoyar las actividades' +
'                                de emprendimiento es la experiencia, los años de asesoría, las investigaciones realizadas, inclusive las quiebras financieras que dejan mayores enseñanzas que los mismos triunfos empresariales.</p>' +
'                            <h4>¿POR QUÉ TODOS EMPRENDEN EN LO MISMO?</h4>' +
'                            <p>El efecto de contagio de ideas es la mayor fuente de emprendimientos, si una persona ve un negocio prosperar, intentará montar lo mismo con algunas mejoras o quizás algunas pequeñas variaciones, pero casi siempre el mercado' +
'                                premia al primero que creó la innovación. No se pretende decir que no se puede emprender en algo ya existente, porque el principio de oferta de servicios y productos hace que existan muchas personas que requieren diferentes' +
'                                ofertas del mismo tipo; pero se pretende dar impulso a las ideas de negocio innovadoras y poco frecuentes ya que en estas se amplifica la probabilidad de éxito.</p>' +
'                            <p>Todos en lo mismo, el efecto de contagio y derivados de la misma actividad hacen que el riesgo de pérdidas sea más alto, es decir, si en un nicho especifico todas las actividades de emprendimiento pertenecen a la misma línea,' +
'                                la demanda puede ser insuficiente para generar las ventas deseadas. (<em>Recomendado leer el libro de “La Estrategia del Océano Azul” W. Chan y Renée Mauborgne</em>) Partiendo de una misma idea, producto o servicio, podemos' +
'                                tener la capacidad de trasformación del mismo, resaltando sus ventajas competitivas y comparativas pero a su vez la apariencia, la forma y la tecnología involucrada.</p>' +
'                            <p>A manera de ejemplo, hemos usado los teléfonos desde hace años, primero los de tecnología análoga ahora los de tecnología digital, es si, sirven para lo mismo, pero las mejoras tecnológicas incorporadas han transformado la' +
'                                forma como nos comunicamos, prácticamente lo que menos usamos de un celular es el servicio de llamadas en la mayoría de los casos.</p>' +
'                            <h4>DE EMPRENDIMIENTO A EMPRESA</h4>' +
'                            <p>En diferentes entrenamientos a los que nuestro equipo de consultores se ha enfrentado, se han recopilado datos importantes. La mayoría de emprendedores se mantienen en ese título por años, es decir, jamás pasan de emprendimiento' +
'                                a empresa. Para realmente comprender si hemos logrado cumplir con todos los aspectos de una empresa debemos hacernos las siguientes preguntas:</p>' +
'                            <ul class="ulLine">' +
'                                <li>¿Las operaciones de ventas son recurrentes?</li>' +
'                                <li>¿Cuento con una estructura financiera definida?</li>' +
'                                <li>¿Tengo formalizada la contabilidad de la empresa?</li>' +
'                                <li>¿Se pagan impuestos?</li>' +
'                                <li>¿Existen roles y funciones determinadas?</li>' +
'                                <li>¿Se manejan estrategias de branding?</li>' +
'                                <li>¿El crecimiento anual es mayor a dos dígitos?</li>' +
'                                <li>¿Puedo como fundador o propietario salirme de la operación y todo funciona?</li>' +
'                                <li>¿Se cuenta con expansión física o digital?</li>' +
'                                <li>¿Hay procesos claros de producción?</li>' +
'                            </ul>' +
'                            <p>Entre otras, estas serían algunas de las preguntas que debemos realmente superar antes de ponernos el título de empresarios.</p>' +
'                        </div>';

export const Articulo5 = '<div class="articuloTxt">' +
'                            <h4>LAS REDES SOCIALES Y LAS VENTAS</h4>' +
'                            <p>Ana una empresaria local, ha tomado la decisión de realizar una estrategias de ventas por medio de redes sociales. La empresa está orientada a la venta de productos de maquillaje, productos de belleza cosmética y algunos productos' +
'                                para el cuidado capilar. Ana ha investigado sobre las redes sociales que más podrían generar ventas para sus productos, teniendo en cuenta que Ana ha vendido toda su vida por medio de catálogos; ella procede a realizar' +
'                                la toma de fotografía con su celular, a editar sus fotos en una aplicación libre y a estructurar su presupuesto de ventas.</p>' +
'                            <p>Días después de subir sus fotos, de pagar por las publicaciones Ana se da cuenta que no ha obtenido resultados, alguien le dijo que aumentara el presupuesto y ella creo una nueva campaña de publicidad triplicando el presupuesto' +
'                                inicial. Pero pasado unos día aun nada de ventas, es más ha recibido insultos de desocupados que merodean las redes sociales.</p>' +
'                            <p>¿Las redes sociales funcionan para vender? Es la gran pregunta que muchos como Ana se han realizado e inclusive han tratado de incursionar en este tipo de publicidad. ¿Por qué no funciona como lo prometen las plataformas o' +
'                                los que hablan de estos temas? Sencillo, por una razón de comportamiento humano en las redes sociales; es muy difícil obtener resultados si crear una comunidad que conecte a las personas con la empresa y luego con el producto</p>' +
'                            <p>¿Quién conocía a Ana? Quizás sus amigos y familiares, pero vender a este primer círculo no tiene sentido, para ello hay otros medios, la idea es llegarle a personas diferentes, lejanas, que realmente estén buscando los servicios' +
'                                o los productos. </p>' +
'                            <h4>¿CUÁL ES EL ERROR DE ANA?</h4>' +
'                            <p>Así como Ana hay muchos que afirman que las redes sociales no sirven para vender, y realmente están en lo cierto, las redes sociales no se hicieron para vender, se hicieron para establecer relaciones, para conectar con otras' +
'                                personas, para ofrecer valor de contenido gratuitamente, para dar visualización, pero jamás para hacer una venta directa. </p>' +
'                            <p>El error es poner publicidad tal cual lo hacen las grandes marcas en la televisión o en la radio. Antes de ofrecer un servicio o producto Ana debió crear contenido, si así como lo escuchas, crear contenido de valor, contenido' +
'                                que de alguna solución a un problema relacionado con sus productos, en el caso del maquillaje, ofrecer un contenido sobre tipos de maquillaje, tipos de piel, las mejores marcas y las peores, contenido sobre cuidados de' +
'                                la piel entre otros.</p>' +
'                            <p>Muchos de los anunciantes en las redes sociales como Facebook, Instagram y YouTube cometen el error de ir directo a la publicidad sin brindar la oportunidad de establecer una relación de conocimiento. A esto le denominamos' +
'                                <strong>“Ventas frías”</strong> Las ventas frías no solo perjudican la marca, van en contra del brandig, en contra de las normas invisibles de la sociedad digital. </p>' +
'                            <h4>CREA CONTENIDO PARA CONECTAR</h4>' +
'                            <p>Lastimosamente se han vuelto las redes sociales un mar de contenidos, casi el 80% del tráfico es en formato de video con una visualización media de 2 minutos, así que cada día las personas dedican menos tiempo a ver un solo' +
'                                video, la práctica de saltar de un video a otro es mar recurrente de lo que nos podemos imaginar. Si no se establece un buen contenido, un buen tema es poco probable que generemos una conexión con nuestro público deseado.</p>' +
'                            <p>El contenido que mayormente conecta es el que trata de la vida cotidiana, de los pensamientos de las personas asociadas a una marca, también el contenido con buena producción llama la atención, una producción con recursos precarios,' +
'                                es decir con baja calidad de video, con edición empírica, ya sea audiovisual o de imágenes no son tan atractivas para generar impacto.</p>' +
'                            <h4>SEGUIDORES O COMUNIDAD</h4>' +
'                            <p>Deja de mirar la cantidad de seguidores que tiene tu cuenta de Facebook o Instagram, o de realizar dolorosas comparaciones con perfiles que modelos, mujeres que muestran piel o quizás de famosos. En términos de ventas, puede' +
'                                ser más relevante conseguir 100 seguidores a los cuales realmente les interese el contenido de tu empresa o tú marca personal que millones de seguidores que no les interesa realizar una compra, solo están allí sin generar' +
'                                ningún tipo de afinidad con tu marca.</p>' +
'                            <p>Hemos pasado de la era de los seguidores a la era de las comunidades, un miembro de tu comunidad asiste a tus Live, comparte el contenido para que otros lo vean, envía datos para que le suministres más información y probablemente' +
'                                se convertirá en un cliente que paga por tus productos o servicios.</p>' +
'                            <h4>ANA DESEA CREAR UNA COMUNIDAD EN REDES SOCIALES</h4>' +
'                            <p>Como ya lo vimos antes, muchos hemos pasado por ser Ana en algún momento, pero según estas experiencias brindemos a Ana algunos de los pasos que puede hacer para crear una comunidad en sus redes sociales:</p>' +
'                            <ol class="ulAlpha">' +
'                                <li>Crea una imagen amigable de tu marca apta para redes sociales</li>' +
'                                <li>Determina de 5 a 10 temas para hablar de ellos en videos cortos</li>' +
'                                <li>El arte gráfico es la esencia, llama la atención con unos buenos post</li>' +
'                                <li>Realiza intervenciones en vivo, avisa con tiempo a tus seguidores</li>' +
'                                <li>Establece una relación, realiza preguntas sobre lo que desean de tu marca</li>' +
'                                <li>Habla un poco de ti y de quien eres o de que se trata tu marca</li>' +
'                                <li>Poco a poco muestra los productos y servicios</li>' +
'                                <li>Ofrece soluciones, algunas de estas gratuitamente, solo algunas</li>' +
'                                <li>Crea formularios para que se suscriban a algún servicios exclusivo</li>' +
'                                <li>Te a la mano una empresa que ayude en la producción de contenido más profesional</li>' +
'                            </ol>' +
'                            <p>Pueden ser muchas más pero, esperemos que Ana y muchos como ella logren iniciar una buena estrategia de contenidos para crear comunidad y poder en un tiempo y con paciencia generar ventas.</p>' +
'                        </div>' ;

export const Articulo6 = '<div class="articuloTxt">' +
'                            <p>La información de las operaciones es fundamental para realizar una buena gestión empresarial, la mayoría de las empresas pequeñas y medianas menosprecian la información recopilada de sus transacciones. Bien nos han enseñado' +
'                                los grandes empresarios <strong>que lo que no se mide no se gestiona</strong>.</p>' +
'                            <h4>LAS EXCUSAS SIEMPRE ESTÁN A LA MANO</h4>' +
'                            <p>La mayor parte de los empresarios se llenan de excusas para no tener los servicios de un contador público que logre organizar los registros. Esto no debe ser impulsado por las regulaciones o las sanciones, esto es una decisión' +
'                                estratégica que aporta información valiosa para tomar decisiones, crear oportunidades y estar al tanto del comportamiento mes a mes de cada uno de los productos o servicios.</p>' +
'                            <p>Las excusas más reiterativas se cobijan bajo la apariencia de no contar con los recursos para el pago de este servicio; este no es un problema externo por el precio, es más un problema de la estructura de gastos que no se tuvieron' +
'                                en cuenta en la creación de la empresa.</p>' +
'                            <p>Otra excusa es la autogestión de la contabilidad, muchos de los empresarios ostentan tener conocimientos en áreas de registros contables, creación de balances y pago de impuestos. La experiencia nos habla por sí misma, las' +
'                                empresas que no cuentan con un contador generalmente presentan los impuestos con errores, no cuentan con balances y mucho menos pueden lograr organizar una junta de socios para tomar decisiones.</p>' +
'                            <h4>¿LA CONTABILIDAD ES SUFICIENTE?</h4>' +
'                            <p>Por más que organicemos la contabilidad, aún no es suficiente, el análisis financiero de la información es fundamental para conocer la vulnerabilidad o fortaleza de la organización. Tener la contabilidad al día, tarea que pocas' +
'                                empresas lo hacen, no es lo único que se necesita; las empresas deben contar con estrategas que fortalezcan los modelos de negocio desde todos los puntos estratégicos: finanzas, operaciones, recursos humanos y mercadeo.</p>' +
'                            <h4>LA CREACIÓN DE REPORTES DEL NEGOCIO</h4>' +
'                            <p>Cuando se emprende, caemos en muchos mentores que no cuentan con la experiencia suficiente, quizás con fama, reconocimiento pero con experiencia precaria en la administración de negocios. Lo primero que se debe hablar en el' +
'                                emprendimiento es sobre la importancia de la gestión de la información, la data, la recolección de datos de las transacciones, las métricas y especialmente los reportes.</p>' +
'                            <p>Los reportes por áreas se establecen con el único fin de medir si las estrategias implementadas están funcionando, o si por el contrario, se deben replantear durante la marcha, casi nadie enseña sobre esto en las escuelas de' +
'                                negocios o en las conferencias de emprendimiento, estas últimas se han tornado en motivación sin fundamento técnico.</p>' +
'                            <p>Finalmente, recomendamos realizar los siguientes reportes mínimos basados en la contabilidad real de la empresa y no en una contabilidad fiscal, que únicamente sirve para presentar impuestos (la nociva nueva tendencia de la' +
'                                contabilidad):' +
'                            </p>' +
'                            <ol>' +
'                                <li>Balance general anual y cuentas de resultado</li>' +
'                                <li>Informe de ventas por tipo de producto y por zona</li>' +
'                                <li>Informe de inventarios (En el caso de tenerlos)</li>' +
'                                <li>Informe de operación</li>' +
'                                <li>Informe financiero</li>' +
'                            </ol>' +
'                            <p>En lo posible dichas estructuras de informes deben ser diseñadas a la medida de cada empresa, las generalidades pueden obviar detalles importantes de las particularidades de cada negocio; la diferencia entre un simple negocio' +
'                                y una empresa es la calidad de la estructuración  en sotos sus ámbitos.</p>' +
'                        </div>' ;
