import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor(private router: Router) { }

  async changePos(path: string, fragment: string) {

    try {
      const dataPromise = await new Promise((resolve, reject) => {
        if (path !== '') {
          this.router.navigate([path]);
        } else if (path === '') {
          path = (window.location.pathname.split('/')[1]).toString();
          this.router.navigate([path]);
        }
        resolve('Router realizado de manera exitosa');
      });
      console.log(dataPromise);
      if (fragment !== '') {
        setTimeout(() => {
          document.getElementById(fragment).scrollIntoView();
        }, 1);
      } else {
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.error(err);
    }
  }
}

