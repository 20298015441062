import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.css"],
})
export class CarouselComponent implements OnInit {
  card = "";
  data = [];
  customOptions = {};

  @Input() typeContent: string;

  @ViewChild("videoPlayer", { static: true }) videoplayer: ElementRef;
  isPlay = false;
  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  constructor() {}

  ngOnInit() {
    this.customOptions = {
      loop: true,
      center: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 1500,
      autoplay: false,
      autoplayHoverPause: true,
      autoplaySpeed: 1500,
      dotsSpeed: 1500,
      navText: ["", ""],
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 3,
        },
      },
      nav: true,
    };

    this.cargarData();
  }

  cargarData() {
    if (this.typeContent === "img") {
      this.data = [
        {
          content: "art1",
          titleContent: "Titulo Imagen22",
        },
        {
          content: "art2",
          titleContent: "Titulo Imagen",
        },
        {
          content: "art3",
          titleContent: "Titulo Imagen",
        },
        {
          content: "art5",
          titleContent: "Titulo Imagen",
        },
        {
          content: "art6",
          titleContent: "Titulo Imagen",
        },
      ];
    } else if (this.typeContent === "vid") {
      this.data = [
        {
          content: "video1",
          titleContent: "Titulo Video",
        },
        {
          content: "video1",
          titleContent: "Titulo Video",
        },
        {
          content: "video1",
          titleContent: "Titulo Video",
        },
        {
          content: "video1",
          titleContent: "Titulo Video",
        },
        {
          content: "video1",
          titleContent: "Titulo Video",
        },
      ];
    } else if (this.typeContent === "lccGalery") {
      this.data = [
        {
          content: "Muestra1",
          titleContent: "Muestra1.svg",
        },
        {
          content: "Muestra2",
          titleContent: "Muestra2.svg",
        },
        {
          content: "Muestra3",
          titleContent: "Muestra3.svg",
        },
      ];
    }
  }
}
