<section id="team" class="sectionPrincipal ">
    <!-- //* Inicio Del Banner Principal  *// -->

    <div class="wow  animate__backInUp col-12 heDiv banner-equipo">
        <div>

        </div>
    </div>
    <!-- //* Fin Del Banner Principal  *// -->
    <div class="w-100 textSection textFontDidact alc al-c px-5">
        <h5 class="px-5">LCC S.A.S. y EduplexBS cuenta con un equipo profesional y técnico de alto nivel capacitado para
            atender los
            requerimientos especiales de las empresas en la actualidad, todo nuestro equipo ha sido capacitado en las
            metodologías
            que se aplicarán en su compañía. Adicionalmente nuestros instructores son sometidos a altas pruebas de
            calidad en la
            educación y un entrenamiento especial en las metodologías y contenidos de nuestra plataforma EduplexBS.
        </h5>
    </div>
    <div class="divTeamMembers">
        <div class="bgTextura imgBackTeam"></div>
        <div class=" divTeamMembersDegrad"></div>


        <div class="container py-5">
            <div class="row contService">
                <div [attr.data-wow-delay]="(0.0 + (i  / 10) ) + 's'" *ngFor="let member of dataTeam; index as i"
                    class="wow animate__zoomIn col-md-4 col-sm-6 mb-5 col-xs-12">
                    <div class="mb-2 text-center">
                        <div class="member-thumb">
                            <img src="assets/ImgEquipo/{{member.img}}" alt="Team Member" class="img-responsive">
                            <div class="overlay contService">
                                <div>
                                    <h4 class="textFontAnthon txtProfileMember">{{member.profile}}</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="text-center mt-2">
                        <h4 class="textFontAnthon nameMember">{{member.name}}</h4>
                    </div>
                </div>
                <!-- end single member -->
            </div>
        </div>
    </div>
</section>